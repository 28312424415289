import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({

    select:{
        width: '20%',
        height: '100%',
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
        background:'#ffffff',
        border: '1px solid #707070',
        // borderTopLeftRadius:'4px',
        // borderTopRightRadius: '4px',
        borderBottom:'0px',
        borderCollapse:'collapse',
    },

    deSelect:{
        width: '20%',
        height: '100%',
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
        background: '#fcfcfc',
        border: '1px solid rgba(0, 0, 0, .2)',
        borderCollapse:'collapse',
        "&:hover": {
            opacity:0.7,
            background:'#ffffff',
            borderColor:'#9E9E9E'
        }
    },

    tab:{
        '&::-webkit-tap-highlight-color': 'transparent'
    }

}));


const MainTab = ({index, setIndex}) => {

    const classes = useStyles();

    return (
        <div className={classes.tab} style={{width:'100%', display:'table', alignItems:'center', height:'32px', cursor:'pointer'}}>
            <Typography 
                className={index === 0 ? classes.select : classes.deSelect} 
                variant={index === 0 ? 'body2' : 'body1'} 
                style={index === 1 ? { borderRight: '0px', borderTopLeftRadius: '4px' }:{borderTopLeftRadius: '4px'}}
                onClick={(e)=>setIndex(0)}>
                오늘날씨
            </Typography>
            <Typography 
                className={index === 1 ? classes.select : classes.deSelect} 
                variant={index === 1 ? 'body2' : 'body1'} 
                style={index === 1? {} : index === 2? { borderRight: '0px', borderLeft: '0px' }: {borderLeft: '0px'}} 
                onClick={(e) => setIndex(1)}>
                미세먼지
            </Typography>
            <Typography 
                className={index === 2 ? classes.select : classes.deSelect} 
                variant={index === 2 ? 'body2' : 'body1'} 
                style={index === 2 ? {} : index === 3? { borderRight: '0px', borderLeft: '0px'}:{borderLeft: '0px'}} 
                onClick={(e) => setIndex(2)}>
                주간날씨
            </Typography>
            <Typography 
                className={index === 3 ? classes.select : classes.deSelect} 
                variant={index === 3 ? 'body2' : 'body1'} 
                style={index === 3 ? { } : {borderLeft: '0px', borderRight: '0px' }} 
                onClick={(e) => setIndex(3)}>
                바람
            </Typography>
            <Typography 
                className={index === 4 ? classes.select : classes.deSelect} 
                variant={index === 4 ? 'body2' : 'body1'} 
                style={index === 3 ? { borderTopRightRadius: '4px', borderLeft: '0px'}:{borderTopRightRadius: '4px'}} 
                onClick={(e) => setIndex(4)}>
                습도
            </Typography>
        </div>
    )
}

export default MainTab;