import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

const HeaderForNow = ({viewType, setSelectDayIndex, selectDayIndex}) => {
    
    return(  
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin:'0px 16px' }} >
                <div styel={{display:'flex', }}> 
                    <div  style={{display: 'flex', flexDirection:'column', cursor:'pointer'}} onClick={(e) => setSelectDayIndex(0)}>
                        <Typography variant='h1' 
                                    style={{ 
                                    fontWeight: selectDayIndex === 0 ? '500' : '300', 
                                    textAlign: 'center', 
                                    color: selectDayIndex !== 3 ? '#ffffff' : '#000000',
                                    lineHeight: '1.5'
                                    }}>오늘</Typography>
                        <Typography variant='h3' style={{ textAlign: 'center', color: selectDayIndex === 3 ? '#000000' : '#ffffff'  }}>{moment().add(0, 'd').format("MM.DD")}</Typography>
                    </div>
                    <div style={{height:'8px'}}/>
                    <div style={{ width: '100%', border: 'solid 1.5px '+(viewType === 0 || selectDayIndex === 3 ?  '#ffffff':'#ffffff'), position: 'relative', top: '1px'
                                , opacity:(selectDayIndex === 0 ? 1:0), backgroundColor: '#ffffff' }} />
                </div>
                <div styel={{display:'flex', }}> 
                    <div  style={{display: 'flex', flexDirection:'column', cursor:'pointer'}} onClick={(e) => setSelectDayIndex(1)}>
                        <Typography variant='h1' 
                                    style={{ 
                                    fontWeight: selectDayIndex === 1 ? '500' : '300', 
                                    textAlign: 'center', 
                                    color: selectDayIndex === 3 ? '#000000' : '#ffffff',
                                    lineHeight: '1.5'
                                    }}>내일</Typography>
                        <Typography variant='h3' style={{ textAlign: 'center', color:selectDayIndex === 3 ? '#000000' : '#ffffff'  }}>{moment().add(1, 'd').format("MM.DD")}</Typography>
                    </div>
                    <div style={{height:'8px'}}/>
                    <div style={{ width: '100%', border: 'solid 1.5px '+(viewType === 0 || selectDayIndex === 3 ?  '#ffffff':'#ffffff'), position: 'relative', top: '1px' 
                                , opacity:(selectDayIndex === 1 ? 1:0), backgroundColor:'#ffffff'}} />
                </div>
                <div styel={{display:'flex', }}>  
                    <div  style={{display: 'flex', flexDirection:'column', cursor:'pointer'}} onClick={(e) => setSelectDayIndex(2)}>
                        <Typography variant='h1' 
                                    style={{ 
                                    fontWeight: selectDayIndex === 2 ? '500' : '300', 
                                    textAlign: 'center', 
                                    color: selectDayIndex === 3 ? '#000000' : '#ffffff',
                                    lineHeight: '1.5'
                                    }}>모레</Typography>
                        <Typography variant='h3' style={{ textAlign: 'center', color:selectDayIndex === 3 ? '#000000' : '#ffffff'  }}>{moment().add(2, 'd').format("MM.DD")}</Typography>
                    </div>
                    <div style={{height:'8px'}}/>
                    <div style={{ width: '100%', border: 'solid 1.5px '+(viewType === 0 || selectDayIndex === 3 ?  '#ffffff':'#ffffff'), position: 'relative', top: '1px' 
                                , opacity:(selectDayIndex === 2 ? 1:0), backgroundColor: '#ffffff'}} />
                </div>
                <div styel={{display:'flex', }}>
                    <div  style={{display: 'flex', flexDirection:'column', cursor:'pointer'}} onClick={(e) => setSelectDayIndex(3)}>
                        <Typography variant='h1' 
                                    style={{ 
                                    fontWeight: selectDayIndex === 3 ? '500' : '300', 
                                    textAlign: 'center', 
                                    color: selectDayIndex === 3 ? '#000000' : '#ffffff',
                                    lineHeight: '1.5'
                                    }}>이후10일</Typography>
                        <Typography variant='h3' style={{ textAlign: 'center', color: selectDayIndex === 3 ? '#000000' : '#ffffff'  }}>{moment().add(3, 'd').format("MM.DD")}~{moment().add(10, 'd').format("MM.DD")}</Typography>
                    </div>
                    <div style={{height:'8px'}}/>
                    <div style={{width: '92%', height:'3px', backgroundColor: (viewType === 0 || selectDayIndex === 3 ?  '#000000':'#ffffff'), position: 'relative', top: '2px' , opacity:(selectDayIndex === 3 ? 1:0), zIndex:99}} />
                </div>
            </div>
            <div style={{ width: '100%', height:'1px' , backgroundColor: '#ffffff', opacity: '0.3', zIndex:0 }} />
            
        </React.Fragment>
    )
}

export default HeaderForNow;