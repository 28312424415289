import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    main :{
        width:'100%',
        //minWidth: '340px',
        maxWidth:'700px',
       // padding:'16px',
        paddingBottom: '0px',
    }

}));


const DetailTemplate = ({ todayDetail, mainTab, map, newsSection,foot}) => {
    const classes = useStyles();

    return (
        <div className={classes.main}>
            <div style={{width:'100%'}}>{todayDetail}</div>
            <div style={{width:'100%', height:'16px'}}/>
            {mainTab}
            {map}
            {newsSection}
            {foot}
        </div>
        
    );
};

export default DetailTemplate;