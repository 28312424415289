import React, {useState, useEffect} from "react";
import moment from 'moment';
import "moment/locale/ko";
import { Typography } from '@material-ui/core';

const Wind =({item, index})=>{
    const [arrow, setArrow] = useState(); 
    const [wsd, setWsd] = useState(); 
    const [vecName, setVecName] = useState(); 

    var sik = Math.floor((Number(item.windDirection)+22.5*0.5)/22.5);

    useEffect(() => {
        switch(sik){
            case 0:
                setArrow('N');
                setVecName('북풍');
                break;
            case 1:
                setArrow('NNE');
                setVecName('북동풍');
                break;
            case 2:
                setArrow('NE');
                setVecName('북동풍');
                break;
            case 3:
                setArrow('ENE');
                setVecName('북동풍');
                break;
            case 4:
                setArrow('E');
                setVecName('동풍');
                break;
            case 5:
                setArrow('ESE');
                setVecName('남동풍');
                break;
            case 6:
                setArrow('SE');
                setVecName('남동풍');
                break;
            case 7:
                setArrow('SSE');
                setVecName('남동풍');
                break;
            case 8:
                setArrow('S');
                setVecName('남풍');
                break;
            case 9:
                setArrow('SSW');
                setVecName('남서풍');
                break;
            case 10:
                setArrow('SW');
                setVecName('남서풍');
                break;
            case 11:
                setArrow('WSW');
                setVecName('남서풍');
                break;
            case 12:
                setArrow('W');
                setVecName('서풍');
                break;
            case 13:
                setArrow('WNW');
                setVecName('북서풍');
                break;
            case 14:
                setArrow('NW');
                setVecName('북서풍');
                break;
            case 15:
                setArrow('NNW');
                setVecName('북서풍');
                break;
            case 16:
                setArrow('N');
                setVecName('북풍');
                break;
            default: arrow = 'S';
        }
    
        //풍속에 따라 아이콘 색상이 변함
        if(item.windSpeed < 0.3){
            setWsd('wind1');
        }
        if(item.windSpeed >= 0.3 && item.windSpeed < 1.6 ){
            setWsd('wind2');
        }
        if(item.windSpeed >= 1.6 && item.windSpeed < 3.4){
            setWsd('wind3');
        }
        if(item.windSpeed >= 3.4 && item.windSpeed < 5.5){
            setWsd('wind4');
        }
        if(item.windSpeed >= 5.5 && item.windSpeed < 8.0){
            setWsd('wind5');
        }
        if(item.windSpeed >= 8.0 && item.windSpeed < 10.8){
            setWsd('wind6');
        }
        if(item.windSpeed >= 10.8 && item.windSpeed < 13.9){
            setWsd('wind7');
        }
        if(item.windSpeed >= 13.9 && item.windSpeed < 17.2){
            setWsd('wind8');
        }
        if(item.windSpeed >= 17.2 && item.windSpeed < 20.8){
            setWsd('wind9');
        }
        if(item.windSpeed >= 20.8 && item.windSpeed < 24.5){
            setWsd('wind10');
        }
        if(item.windSpeed >= 24.5 && item.windSpeed < 28.5){
            setWsd('wind11');
        }
        if(item.windSpeed >= 28.5){
            setWsd('wind12');
        }
    }, [item])

    

        
    return (
        <React.Fragment key={index}>
            <div style={{height:'4px'}}/>
            <img src={'/images/wind/'+wsd+'.png'} id='vec' alt='vec' style={{width:'24px', height:'24px', transform: 'rotate('+(item.windDirection-180)+'deg)'}}/>
            <div style={{height:'6px'}}/>
            <div><Typography style={{fontWeight:300, opacity:(moment().format('YYYYMMDDHH00') === item.key ? 1 :0.6)}}>{vecName}</Typography></div>
            <div style={{height:'16px'}}/>
            <div><Typography>{Math.round(item.windSpeed)}m/s</Typography></div>
        </React.Fragment>
    )
}

export default Wind;