import axios from "axios";
import moment from "moment";


export const SERVER_PATH = process.env.REACT_APP_API_SERVER_URL;

export const get3Days = async (position) => {

    const path = SERVER_PATH+'/contents/weather/timeline-weather';
    //const { baseDate, baseTime } = getDateTimeToday();
    const resultList = await axios({
        method: 'get',
        baseURL: path,
        params: { lon: position.nx, lat: position.ny },
    }).then((response) => {
        const retVal = response.data.results;
        
        return retVal;
    }).catch(err => {
        console.log(err)
        return null;
    });
    return resultList;
}


export const get10Days = async (position) => {

    const path = SERVER_PATH + '/contents/weather/ten-days-after';
    const resultList = await axios({
        method: 'get',
        baseURL: path,
        params: { 
            lon: position.nx, lat: position.ny
         }
    }).then((response) => {
        const retVal = response.data.results;
        return retVal;
    }).catch(err => {
        return null;
    });
    return resultList;
}

export const getAirPollution = async (position) => {

    const path = SERVER_PATH + '/contents/weather/air-pollution';
    const resultList = await axios({
        method : 'get',
        baseURL : path, 
        params :{   ymd: position.ymd? position.ymd: moment().format('YYYYMMDD'),
                    lon: position.nx, 
                    lat: position.ny
                }, 
    }).then((response) => {
        const retVal = response.data.results;
        return retVal;
    }).catch(err => {
        console.log(err);
        return null;
    });
    return resultList;

}


export const getToday = async (position) => {

    const path = SERVER_PATH + '/contents/weather/today';

    const resultList = await axios({
        method: 'get',
        baseURL: path,
        params: { lon: position.nx, lat: position.ny },
    }).then((response) => {
        const retVal = response.data.results;
        return retVal;
    }).catch(err => {
        console.log(err)
        return null;
    });
    return resultList;
}


export const getTomorrow = async (position) => {

    const path = SERVER_PATH + '/contents/weather/tomorrow';
    
    const resultList = await axios({
        method: 'get',
        baseURL: path,
        params: { lon: position.nx, lat: position.ny },
    }).then((response) => {
        const retVal = response.data.results;
        return retVal;
    }).catch(err => {
        console.log(err)
        return null;
    });
    return resultList;
}

export const getDayAT = async (position) => {
 
    const path = SERVER_PATH + '/contents/weather/day-af-tomorrow';
    
    const resultList = await axios({
        method: 'get',
        baseURL: path,
        params: { lon: position.nx, lat: position.ny },
    }).then((response) => {
        const retVal = response.data.results;
        return retVal;
    }).catch(err => {
        console.log(err)
        return null;
    });
    return resultList;
}

export const getApiKeywordNews  = (index, size, keyword) => {
    const path = SERVER_PATH + `/service/api/v1/news`;

    let startDate = moment().add(-1,'d').format('YYYYMMDDHHmmss')
    let endDate = moment().format('YYYYMMDDHHmmss')
    const params = {
        from_dt: startDate,
        to_dt: endDate,
        page_no: 1,
        size: size,
        sort: index,
        search_opt: 0,
        keyword: keyword
    }
    return axios({
        method:'get',
        baseURL: path,
        params: params
    });
}

export const getAddress = async (position) => {

    const path = SERVER_PATH + '/contents/weather/location-info';
    
    const resultList = await axios({
        method: 'get',
        baseURL: path,
        params: { lon: position.nx, lat: position.ny },
    }).then((response) => {
        const retVal = response.data.location_nm_full;
        return retVal;
    }).catch(err => {
        console.log(err)
        return null;
    });
    return resultList;
}


export const getForecastMap = async () => {
    const mapPath = SERVER_PATH+'/contents/weather/forecast-map?ymd='+ moment().format('YYYYMMDD');
    const resultList =  await axios({
        method: 'get',
        baseURL: mapPath,
    }).then((response) => {
        const retVal = response.data.results;
        return retVal;
    }).catch(err => {
        console.log(err);
    });
    return resultList;
}

export const getMainForecastMap = async () => {
    const mapPath = SERVER_PATH+'/contents/weather/home/forecast-map?ymd='+ moment().format('YYYYMMDD');
    const resultList =  await axios({
        method: 'get',
        baseURL: mapPath,
    }).then((response) => {
        const retVal = response.data.results;
        return retVal;
    }).catch(err => {
        console.log(err);
    });
    return resultList;
}

// 응답 인터셉터 추가
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log("error", error);
        return Promise.reject(error);
    });
