import { Typography, makeStyles } from '@material-ui/core';
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import HeaderForNow from '../nowPostion/HeaderForNow';
import HeaderForMap from '../nowPostion/HeaderForMap';
// import { useHistory, Route } from 'react-router-dom';
// import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
    icon: {
        "&:hover": {
            opacity:0.7
        }
    }
}));

const Header = ({position, handleView, handleDateChange, setSelectDayIndex, selectDayIndex, address, view}) => {
    const classes = useStyles();
    const [viewType, setViewType] = useState(0);
    // const arr = Array(6).fill().map((item, index) => ++index);
    // const arr3days = Array(3).fill().map((item, index) => ++index);
    const [isModal , setIsModal] = useState(false);

    useEffect(() => {
        window.parent.postMessage({ name: 'modal',  action: 'loaded'}, '*');//로딩 완료
        const postAction = (e) => {
            if(e.data && e.data.name === 'home'){
                setIsModal(true);
            }
        }
        window.addEventListener('message',postAction, true);
        return () => {
            window.removeEventListener("message", postAction, true);
        };
    }, [])

    useEffect(()=>{
        if(view === 'true'){
            handleViewTypeClick(1);
        }
    },[])

    const handleViewTypeClick = (type) => {
        setViewType(type);
        setSelectDayIndex(0);
        handleView(type);
    }

    const handleMoveClick = () =>{
        window.parent.postMessage({name:'backHandler'},'*');      

    }

    const closeModal = () =>{
        
        window.parent.postMessage({ name: 'modal',  action: 'close'}, '*');
        window.parent.postMessage({ name:'update'},'*');  
    }

    return (
        <div style={{ padding: '16px', paddingBottom:'0px'}}>
            <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center',  }}>
                <div style={{ display: 'flex' }}>
                {viewType === 0 ? 
                <React.Fragment>
                    {selectDayIndex === 3?
                    <img className={classes.icon} src='/images/common/ic_move_black.png' alt='>' style={{width:'24px', height:'24px', cursor:'pointer',}} 
                        onClick={(e)=> { isModal ? closeModal() : handleMoveClick()}}/>
                    :<img className={classes.icon} src='/images/common/ic_move.png' alt='>' style={{width:'24px', height:'24px', cursor:'pointer'}} 
                    onClick={(e)=> { isModal ? closeModal() : handleMoveClick()}}/>
                    }
                    <div style={{width:'4px'}}/>
                    <Typography variant='h1' style={{ fontWeight: '500', color: selectDayIndex === 3 ? '#000000' : '#ffffff'}} >{address}</Typography>
                </React.Fragment>
                    :
                <React.Fragment>
                    <img className={classes.icon} src='/images/common/ic_move_black.png' alt='>' style={{width:'24px', height:'24px', cursor:'pointer'}} 
                        onClick={(e)=> { isModal ? closeModal() : handleMoveClick()}}/>
                    <div style={{width:'4px'}}/>
                    <Typography variant='h1' style={{ fontWeight: '500', color: '#000000' }}>전국</Typography>
                </React.Fragment>
                }
                </div>
                <div style={{ display: 'flex' }}>
                    <Typography variant='h2' style={{ fontWeight: viewType === 0 ? '500' : '200' , color: viewType === 1 || selectDayIndex === 3 ?  '#000000' :'#ffffff', cursor:'pointer' }} onClick={(e) => handleViewTypeClick(0)}>현위치</Typography>
                    <div style={{ width: '12px' }} />
                    <Typography variant='h2' style={{ fontWeight: viewType === 1 ? '500' : '200' , color: viewType === 1 || selectDayIndex === 3 ?  '#000000' :'#ffffff' , cursor:'pointer' }} onClick={(e) => handleViewTypeClick(1)}>전국</Typography>
                </div>
            </div>
            <div style={{ height: '18px' }}/>
            {viewType === 1 &&
                <HeaderForMap setSelectDayIndex={setSelectDayIndex} selectDayIndex={selectDayIndex}/>
            }
            {viewType === 0 &&
                <HeaderForNow viewType={viewType} setSelectDayIndex={setSelectDayIndex} selectDayIndex={selectDayIndex}/>
            }
        </div>
        
    )
}

export default Header;
