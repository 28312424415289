import React,{useState, useEffect} from 'react';
import { Typography } from '@material-ui/core';
import Sky from '../../common/Sky';
import moment from 'moment';

const WeatherOfMap = ({value, code, selectDayIndex}) =>{

    //전국 좌표
    let xyList = [
        { 'region': '인천', 'nx': 55 , 'ny' : 124, 'x' : '109px'  , 'y' : '77px'    },
        { 'region': '서울', 'nx': 60 , 'ny' : 127, 'x' : '149.9px', 'y' : '79.9px'  },
        { 'region': '춘천', 'nx': 73 , 'ny' : 134, 'x' : '196px'  , 'y' : '61.5px'  },
        { 'region': '강릉', 'nx': 92 , 'ny' : 131, 'x' : '251.2px', 'y' : '71.1px'  },
        { 'region': '독도', 'nx': 144, 'ny' : 123, 'x' : '289.9px', 'y' : '86.5px'  },
        { 'region': '백령', 'nx': 21 , 'ny' : 135, 'x' : '54.4px' , 'y' : '104px'   },
        { 'region': '수원', 'nx': 60 , 'ny' : 121, 'x' : '164.4px', 'y' : '124.9px' },
        { 'region': '청주', 'nx': 69 , 'ny' : 106, 'x' : '204.4px', 'y' : '132.9px' },
        { 'region': '안동', 'nx': 91 , 'ny' : 106, 'x' : '270.4px', 'y' : '150.5px' },
        { 'region': '울산', 'nx': 102, 'ny' : 84 , 'x' : '275.4px', 'y' : '198.9px' },
        { 'region': '대구', 'nx': 89 , 'ny' : 90 , 'x' : '232.4px', 'y' : '184.9px' },
        { 'region': '대전', 'nx': 67 , 'ny' : 100, 'x' : '174.4px', 'y' : '184.9px' },
        { 'region': '전주', 'nx': 63 , 'ny' : 89 , 'x' : '123px'  , 'y' : '177px'   },
        { 'region': '광주', 'nx': 58 , 'ny' : 74 , 'x' : '142.4px', 'y' : '221.9px' },
        { 'region': '창원', 'nx': 90 , 'ny' : 77 , 'x' : '214.4px', 'y' : '239.5px' },
        { 'region': '부산', 'nx': 98 , 'ny' : 76 , 'x' : '256.4px', 'y' : '248.5px' },
        { 'region': '목포', 'nx': 50 , 'ny' : 67 , 'x' : '95.2px' , 'y' : '271.6px' },
        { 'region': '여수', 'nx': 73 , 'ny' : 66 , 'x' : '178.4px', 'y' : '280.5px' },
        { 'region': '제주', 'nx': 52 , 'ny' : 38 , 'x' : '111.3px', 'y' : '336.4px' },
    ];

    return(
        <React.Fragment>
            {xyList && xyList.map((item, index)=>{
                if(value.nx == item.nx && value.ny == item.ny){ 
                    return(
                        <React.Fragment key={index}>
                            <div style={{position:'absolute', left:item.x, top:item.y }}>
                                {selectDayIndex === 0 ?
                                    <React.Fragment>
                                        {code === 0 && <Sky item={{skyState:value.now_sky, ptyState:value.now_pty}} wid={'22.1px'} 
                                                            pm={moment().format('HHmm')<1800? 0:9}/>} 
                                        {code === 1 && <Sky item={{skyState:value.now_am_sky, ptyState:value.now_am_pty}} pm={0} wid={'22.1px'}/>}    
                                        {code === 2 && <Sky item={{skyState:value.now_pm_sky, ptyState:value.now_pm_pty}} pm={9} wid={'22.1px'}/>}   
                                    </React.Fragment>
                                : selectDayIndex === 1 ?
                                    <React.Fragment>
                                        {code === 1 && <Sky item={{skyState:value.d1_am_sky, ptyState:value.d1_am_pty}} wid={'22.1px'} pm={0}/>}    
                                        {code === 2 && <Sky item={{skyState:value.d1_pm_sky, ptyState:value.d1_pm_pty}} wid={'22.1px'} pm={9} />}   
                                    </React.Fragment> 
                                : selectDayIndex === 2 ?
                                    <React.Fragment>
                                        {code === 1 && <Sky item={{skyState:value.d2_am_sky, ptyState:value.d2_am_pty}} wid={'22.1px'} pm={0}/>}    
                                        {code === 2 && <Sky item={{skyState:value.d2_pm_sky, ptyState:value.d2_pm_pty}} wid={'22.1px'} pm={9} />}   
                                    </React.Fragment> 
                                : selectDayIndex === 3 ?
                                    <React.Fragment>
                                        {code === 1 && <Sky item={{skyState:value.d3_am_sky, ptyState:value.d3_am_pty}} wid={'22.1px'} pm={0}/>}    
                                        {code === 2 && <Sky item={{skyState:value.d3_pm_sky, ptyState:value.d3_pm_pty}} wid={'22.1px'} pm={9} />}   
                                    </React.Fragment> 
                                : selectDayIndex === 4 ?
                                    <React.Fragment>
                                        {code === 1 && <Sky item={{skyState:value.d4_am_sky, ptyState:value.d4_am_pty}} wid={'22.1px'} pm={0}/>}    
                                        {code === 2 && <Sky item={{skyState:value.d4_pm_sky, ptyState:value.d4_pm_pty}} wid={'22.1px'} pm={9} />}   
                                    </React.Fragment> 
                                : 
                                <React.Fragment>
                                    {code === 1 && <Sky item={{skyState:value.d5_am_sky, ptyState:value.d5_am_pty}} wid={'22.1px'} pm={0}/>}    
                                    {code === 2 && <Sky item={{skyState:value.d5_pm_sky, ptyState:value.d5_pm_pty}} wid={'22.1px'} pm={9} />}   
                                </React.Fragment> 
                                }   
                                <div style={{position:'relative', top:'-3px', left:'-3.5px'}}>
                                    <Typography style={{fontSize:'8px', lineHeight:'11px', letterSpacing:'-0.32px', fontWeight:'500', color:'#000000', opacity:1, whiteSpace:'nowrap'}}>
                                        {item.region}
                                        {selectDayIndex === 0 ? <React.Fragment>{code === 0 ? value.now_temp: code === 1 ? value.now_am_temp : value.now_pm_temp}℃</React.Fragment>
                                        :selectDayIndex === 1 ? <React.Fragment>{code === 1 ? value.d1_am_temp : value.d1_pm_temp}℃</React.Fragment>
                                        :selectDayIndex === 2 ? <React.Fragment>{code === 1 ? value.d2_am_temp : value.d2_pm_temp}℃</React.Fragment>
                                        :selectDayIndex === 3 ? <React.Fragment>{code === 1 ? value.d3_am_temp : value.d3_pm_temp}℃</React.Fragment>
                                        :selectDayIndex === 4 ? <React.Fragment>{code === 1 ? value.d4_am_temp : value.d4_pm_temp}℃</React.Fragment>
                                        : <React.Fragment>{code === 1 ? value.d5_am_temp : value.d5_pm_temp}℃</React.Fragment>
                                        }
                                    </Typography>   
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            })}
        </React.Fragment>
    )
}
export default WeatherOfMap;

