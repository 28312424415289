import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({

    select:{
        background: '#ed5255',
        padding: '4px 15px 5px 14px',
        height:'28px',
        width:'27px'
    },

    deSelect:{
        opacity: 1,
        background: '#ffffff',
        borderTop: '1px solid #e8e8e8',
        borderBottom: '1px solid #e8e8e8',
        height:'28px',
        width:'27px'
    },
    selectFont:{
        fontSize: '13px',
        color: '#ffffff',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 2.38,
        letterSpacing: '-0.52px',
        opacity: 1,
    },

    deSelectFont:{
        fontSize: '13px', 
        fontColor: '#000000', 
        opacity: 0.6,
    }


}));

const Tab = ({code, setCode, selectDayIndex}) => {   

    const classes = useStyles();

    return (
        <div style={{ display: 'flex', width: '164px', height: '28px'}}>
            {selectDayIndex === 0 &&
            <div style={code === 0?
                {flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                borderRight:'1px solid rgba(0,0,0,0)',
                borderLeft:'1px solid rgba(0,0,0,0)'
                }
                :
                {flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                borderRight:'1px solid #e8e8e8',
                borderLeft:'1px solid #e8e8e8'}}
                onClick={(e) => setCode(0)}
                className={code === 0 ? classes.select : classes.deSelect} >
                <Typography className={code === 0 ? classes.selectFont : classes.deSelectFont}>현재</Typography>
            </div>
            }            
            <div style={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                borderTopLeftRadius: selectDayIndex === 0? '0px':'4px',
                borderBottomLeftRadius: selectDayIndex === 0? '0px':'4px',
            }}
                onClick={(e) => setCode(1)}
                className={code === 1 ? classes.select : classes.deSelect} >
                <Typography className={code === 1 ? classes.selectFont : classes.deSelectFont}>오전</Typography>
            </div>
            <div style={code === 2 ?
                {
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    borderTopRightRadius:'4px',
                    borderBottomRightRadius:'4px',
                    borderRight:'1px solid rgba(0,0,0,0)',
                    borderLeft:'1px solid rgba(0,0,0,0)'
                }:{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    borderTopRightRadius:'4px',
                    borderBottomRightRadius:'4px',
                    borderRight:'1px solid #e8e8e8',
                    borderLeft:'1px solid #e8e8e8'
                }}
                onClick={(e) => setCode(2)}
                className={code === 2 ? classes.select : classes.deSelect} >
                <Typography className={code === 2 ? classes.selectFont : classes.deSelectFont}>오후</Typography>
            </div>
        </div>
    )
}

export default Tab;