import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import DetailSky from '../../common/DetailSky';
import DetailWind from '../../common/DetailWind';
import DetailRain from '../../common/DetailRain';
import DetailDust from '../../common/DetailDust';
import DetailWatery from '../../common/DetailWatery';

import moment from 'moment';

const NowPosition = ({ imgHeight, selectDayIndex, setBackground, data, dustData}) => {

    useEffect(() => {
        // 배경화면
        if(data){
            if(data[0].ptyState === '0' && data[0].skyState === '1'){
                if(moment().format('HHmm') < '1800'){
                    setBackground('sunny_day');
                }else{
                    setBackground('sunny_night');
                }         
            }else if(data[0].ptyState === '3' || data[0].ptyState === '7'){
                setBackground('snow_day');
            }else if(data[0].ptyState === '1' || data[0].ptyState === '2'|| data[0].ptyState === '4'|| data[0].ptyState === '5'|| data[0].ptyState === '6'){
                setBackground('rain_day');
            }else if(data[0].ptyState === null && data[0].skyState === null){
                setBackground('error');
            }else{
                setBackground('cloud_day');
            }
        }else{
            setBackground('error');
        }
    },[data])
    
    
    return (
        <React.Fragment>
        
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: imgHeight ? (imgHeight - 110 + 'px') : 'auto',
            maxWidth:'700px',
        }}
        >
            <div style={{ height: '32px' }} />
            <div style={{ flexGrow: 1, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                {data? <DetailSky data={data[0]} selectDayIndex={selectDayIndex}/>
                        : <>
                        <img src={'/images/common/DetailImg/ic_weather_error.png'} alt="error" style={{width:'80px'}}/>
                        <Typography variant={'h5'} style={{paddingTop:'16px', textAlign:'center', paddingLeft:'45px', paddingRight:'45px' }}>데이터 제공사 문제로 일시적으로 일부 날씨 서비스를 이용할 수 없습니다</Typography>
                        </>}             
            </div>
            {data && 
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '82px' }}>
                    <Typography variant={'h2'} style={{ textAlign: 'center' }}>초 미세먼지</Typography>
                    <DetailDust dustData={dustData} dust={'PM25'}/>
                </div>
                <div style={{ height: '80px', borderRight: '1px solid #ffffff', opacity: '0.5' }} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '82px' }}>
                    <Typography variant={'h2'} style={{ textAlign: 'center' }}>미세먼지</Typography>
                    <DetailDust dustData={dustData} dust={'PM10'}/>
                </div>
                <div style={{ height: '80px', borderRight: '1px solid #ffffff', opacity: '0.5' }} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '82px' }}>
                    <Typography variant={'h2'} style={{ textAlign: 'center' }}>바람</Typography>
                    {data && <DetailWind item={{wsd: data[0].windSpeed, vec:data[0].windDirection}} />}
                </div>
                <div style={{ height: '80px', borderRight: '1px solid #ffffff', opacity: '0.5' }} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '82px' }}>
                    {selectDayIndex === 0? 
                        <React.Fragment>
                            <Typography variant={'h2'} style={{ textAlign: 'center' }}>습도</Typography>
                            <DetailWatery reh={data &&data[0].humidity}/>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Typography variant={'h2'} style={{ textAlign: 'center' }}>강수확률</Typography>
                            <DetailRain pop={data &&data[0].pop} pty={data &&data[0].ptyState}/>
                        </React.Fragment>
                    }                   
                </div>
            </div>}
            <div style={{ height: '20px' }} />
        </div>  
        </React.Fragment>
    )
}

export default NowPosition;