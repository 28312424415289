import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

const HeaderForMap = ({setSelectDayIndex, selectDayIndex}) => {
    const arr = Array(6).fill().map((item, index) => ++index);

    return(  
        <React.Fragment>
            <div style={{ width:'100%', height:'1px', backgroundColor: '#000000', opacity: '0.1' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop:'4px'}}>
                {arr.map((item, index)=>{
                    return (
                        <div onClick={(e) => setSelectDayIndex(index)} style={{cursor:'pointer'}}>
                            <Typography variant='h1' 
                                style={{ 
                                    fontWeight: selectDayIndex === index ? '400' : '300', 
                                    textAlign: 'center', 
                                    color: '#000000',
                                    lineHeight: '1.5'
                                }}>{moment().add(index,'d').format("ddd")}</Typography>
                            <Typography variant='h3' style={{ textAlign: 'center', color: '#000000' }}>{moment().add(index, 'd').format("MM.DD")}</Typography>
                            <div style={{ width: '30px', border: 'solid 2px #000000', opacity: selectDayIndex === index ? '1' : '0', position: 'relative', top: '1px', }} />
                        </div>
                    )}
                )}
            </div>
        </React.Fragment>
    )
}

export default HeaderForMap;