import React from 'react';
import { makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    main :{
        width:'100%',
        minWidth: '340px',
        maxWidth:'700px',
        paddingLeft:'16px',
        paddingRight:'16px',
        // paddingBottom: '20px',
        // padding:'16px',
        paddingBottom: '26px',
    }

}));

const MainTemplate = ({ titleBar, tab, main, handleClick}) => {
    const classes = useStyles();

    return (
        <div className={classes.main} >
            {titleBar}
            {/* <div style={{height:'16px'}} /> */}
            {tab}
            <div onClick={handleClick}>{main}</div>
        </div>
    );
};

export default MainTemplate;