import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  overFlow: {
    display: "-webket-box",
    wordWrap: "break-word",
    "&::-webkit-line-clamp": 2,
    "&::-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
}));

const NewsSection = ({ item, index, selecMenu, length }) => {
  let day = moment.duration(moment().diff(item.news_dt)).days();
  let hour = moment.duration(moment().diff(item.news_dt)).hours();
  let minute = moment.duration(moment().diff(item.news_dt)).minutes();
  const classes = useStyles();
  const [error, setError] = useState([]);

  useEffect(() => {
    setError([]);
  }, [selecMenu]);

  if (item.img_urls.lastIndexOf("http") > 0) {
    //이미지 주소가 두 개 이상 넘어오면
    item.img_urls = item.img_urls.substring(
      0,
      item.img_urls.lastIndexOf("http")
    ); //맨 앞의 이미지 주소를 썸네일로 띄운다.
  }

  const handleImageError = (medCd, cnt, index) => (e) => {
    cnt = cnt + 1;
    if (medCd && cnt < 2) {
      e.target.src =
        `https://cdn.rozeus.com/mediaLogo/rectangle/` +
        `${item.med_cd.toLowerCase()}.gif`;
      e.target.width = "106";
      e.target.height = "40";
      e.target.style = { padding: "4px" };
    } else {
      e.target.src =
        `${"https://cdn.rozeus.com"}` + "/image/default/rozeus_default.png";
      e.target.width = 0;
      setError(error.concat(index));
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "108px 1fr",
          padding: "4px 16px 4px 16px",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={(e) => {
          window.parent.postMessage({ name: "news_view", item: item }, "*");
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            borderRadius: "4px",
            border: "1px solid #e6e6e6",
            width: "108px",
            height: "72px",
            position: "relative",
          }}
        >
          {item.img_urls ? (
            <React.Fragment>
              <img
                src={item.img_urls}
                style={{
                  borderRadius: "4px",
                  objectFit: "contain",
                  overflow: "hidden",
                  border: "1px solid #e6e6e6",
                  zIndex: 55,
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                width="108px"
                height="72px"
                alt="pictures"
                onError={handleImageError(item.med_cd, 0, index)}
              />
              {/* <img
                src={item.img_urls}
                style={{
                  borderRadius: "4px",
                  objectFit: "cover",
                  overflow: "hidden",
                  border: "1px solid #e6e6e6",
                  filter: "blur(1px) brightness(0.5)",
                  zIndex: 22,
                  position: "absolute",
                  top: 0,
                }}
                width="108px"
                height="72px"
                alt="pictures"
                onError={(e) => (e.target.src = "")}
              /> */}
            </React.Fragment>
          ) : item.med_cd ? (
            <img
              src={
                `https://cdn.rozeus.com/mediaLogo/rectangle/` +
                `${item.med_cd.toLowerCase()}.gif`
              }
              alt="pictures"
              onError={handleImageError(item.med_cd, 1, index)}
              style={{ width: "108px", height: "auto", overflow: "hidden" }}
            />
          ) : (
            <Typography
              style={{
                fontSize: "12px",
                letterSpacing: "-0.52px",
                color: "#202020",
                opacity: 1,
              }}
            >
              {item.med_nm}
            </Typography>
          )}

          {error.indexOf(index) > -1 && (
            <div
              style={{
                background: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "108px",
                height: "auto",
                borderRadius: "4px",
              }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  letterSpacing: "-0.52px",
                  color: "#202020",
                  opacity: 1,
                }}
              >
                {item.med_nm}
              </Typography>
            </div>
          )}
        </div>
        <div
          style={{
            marginLeft: "16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "",
            width: "100%",
          }}
        >
          <div>
            <Typography
              variant="body2"
              className={classes.overFlow}
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "normal",
                maxHeight: "40px",
                minWidth: "200px",
              }}
            >
              {item.title}
            </Typography>
          </div>
          <div style={{ display: "flex", paddingTop: "4px" }}>
            <Typography component="span" style={{ fontSize: "12px" }}>
              {item.med_nm}&nbsp;&nbsp;|&nbsp;&nbsp;
            </Typography>
            <Typography component="span" style={{ fontSize: "12px" }}>
              {item.cat_nm}&nbsp;&nbsp;|&nbsp;&nbsp;
            </Typography>
            <Typography component="span" style={{ fontSize: "12px" }}>
              {day > 0
                ? day + "일"
                : hour > 0
                ? hour + "시간"
                : minute > 0 && minute + "분"}{" "}
              전
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "19px",
        }}
      >
        <div style={{ width: "200px" }}></div>
        {index === length - 1 ? (
          <></>
        ) : (
          <div
            style={{
              width: "100%",
              height: "1px",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default NewsSection;
