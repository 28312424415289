import React, { useEffect, useState } from "react";
import {Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

function DetailRain({pop, pty}){
    const [rain, setRain] = useState('');
    const [load, setLoad] = useState(false);

    useEffect(()=>{  
        if(pop){
            if(pop == 0){
                setRain('sunny');
                setLoad(true);
            }else{
                setRain('rain');
                setLoad(true);
            }
        }else{
            if(pty == 0){ //강수형태 없음
                setRain('sunny');
                setLoad(true);
            }else{
                setRain('rain');
                setLoad(true);
            }      
        }  
        

    },[pop, pty])

    return(
        <React.Fragment>
            <div style={{textAlign:'center', paddingTop:'9px', paddingBottom:'5px'}}>
                {/* <Fade in={load}> */}
                    <img src={'/images/common/DetailImg/rain/'+rain+'.png'} id='rain' alt='vec' style={{width:'24px', height:'24px'}}/>
                {/* </Fade> */}
            </div>        
            <div style={{textAlign:'center'}}>
                {/* <Fade in={load}> */}
                    <Typography variant={'h2'} style={{}}>{pop?pop:pty==0?pty:100}%</Typography> 
                {/* </Fade>    */}
            </div>  
        </React.Fragment>
    );
}

export default DetailRain;