import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { themeType } from './common/constants';

import MainContainer from './containers/MainContainer';

import { normal } from './css/CssTheme';

import { BrowserRouter, Route } from 'react-router-dom';


function get_query() {
  var url = document.location.href;
  var qs = url.substring(url.indexOf('?') + 1).split('&');
  for (var i = 0, result = {}; i < qs.length; i++) {
    qs[i] = qs[i].split('=');
    result[qs[i][0]] = decodeURIComponent(qs[i][1]);
  }
  return result;
}

const App = ({ layout }) => {

  const [theme, setTheme] = useState(normal);
  const urlParam = get_query();
  
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <MainContainer urlParam={urlParam} />
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default inject(({ layout }) => ({
  layout: layout
}))(observer(App));
