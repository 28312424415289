import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Sky from '../common/Sky';
import moment from 'moment';
import FadeIn from 'react-fade-in';

function SimpleMain({position, setPosition, address, dustData, positionBtn, setPositionBtn, data}){  
    useEffect(() => {
        window.parent.postMessage({ name: 'weatherLocation', request: 'location', click:false}, '*');
    }, []);
    
    const handleClick = () => {
        window.parent.postMessage({ name: 'new_window',  action: 'transform', url: process.env.REACT_APP_URL+'/rozeus_weather/?mode=detail&nx='+position.nx+'&ny='+position.ny+'&address='+address}, '*');
    }

    return(
        <>
        <FadeIn transitionDuration={500}>
        <div style={{display:'grid', gridTemplateColumns:'50px 1fr 1fr', rowGap:'10px', gridAutoRows:'83px', alignItems:'center'}}>
            {data && data.map((item, index)=>{ 
                return(
                    <> 
                    <Sky item={item} wid={'40px'} pm={moment().format('HHmm')<1800? 0:9} onClick={handleClick} main={true} key={index-10}/>
                    <div onClick={handleClick} key={index} style={{cursor:'pointer'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Typography style={{fontSize:'16px', letterSpacing:'-0.48px', color:'#000000', fontWeight:'bold', opacity:1 }}>{item.temp}゜</Typography> 
                            <Typography style={{fontSize:'16px', letterSpacing:'-0.48px', color:'#000000', fontWeight:300, opacity:1, position:'relative', left:'-5px' }}>{
                                item.ptyState === '0' ?
                                    <React.Fragment>
                                        {item.skyState === '1' && '맑음'      }
                                        {item.skyState === '3' && '구름많음'  }
                                        {item.skyState === '4' && '흐림'      }
                                    </React.Fragment>
                                :
                                    <React.Fragment>
                                        {item.ptyState === '1' && '비'            }
                                        {item.ptyState === '2' && '비/눈'         }
                                        {item.ptyState === '3' && '눈'            }
                                        {item.ptyState === '4' && '소나기'        }
                                        {item.ptyState === '5' && '빗방울'        }
                                        {item.ptyState === '6' && '빗방울/눈날림' }
                                        {item.ptyState === '7' && '눈날림'        }
                                    </React.Fragment> 
                            }</Typography>
                            <img src='/images/common/ic_move_right.png' alt="move_button" width="24" height="24" style={{position:'relative', left:'-5px', top:'1px' }}/>
                        </div>
                        <div style={{display:'flex'}}>
                            {dustData && dustData.map((item,index)=>{ 
                                return(
                                    <>        
                                    {item.informCode === "PM10" && 
                                    <div style={{display:'flex'}} key={index}>
                                        <Typography style={{fontWeight:300, opacity:1}}>미세&nbsp;</Typography> 
                                        <Typography style={{fontWeight:300, opacity:1, color: (item.informGrade === '좋음'?'#67AC2B':item.informGrade === '보통'?'#E9AB00': item.informGrade === '나쁨'?'#F98018':'#B42E2E')}}>{item.informGrade}&nbsp;</Typography>
                                    </div>}
                                    {item.informCode === "PM25" && 
                                    <div style={{display:'flex'}}  >
                                        <Typography  style={{fontWeight:300, opacity:1}}>&#183;&nbsp;초미세&nbsp;</Typography> 
                                        <Typography  style={{fontWeight:300, opacity:1, color:(item.informGrade === '좋음'?'#67AC2B':item.informGrade === '보통'?'#E9AB00': item.informGrade === '나쁨'?'#F98018':'#B42E2E')}}>{item.informGrade}</Typography>
                                    </div>}
                                    </>
                                )})
                            }
                        </div>
                    </div>
                    </>
                )})
            }
            {data && <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                <Typography style={{fontWeight:300, paddingRight:'6px', opacity:0.7}}>{address}</Typography>
                <img src='/images/common/ic_position.png' style={{display:'block', width:'14px', height:'14px', cursor:'pointer'}} alt='POSITION ICON'
                    onClick={(e)=>{window.parent.postMessage({ name: 'weatherLocation', request: 'location', click:true}, '*'); setPositionBtn(!positionBtn);}} />
            </div>}
        </div>
        </FadeIn>
        </>
    );
}

export default SimpleMain;