import React, { useEffect, useState } from 'react';
import MainTemplate from '../templates/MainTemplate';
import { inject, observer } from 'mobx-react';
import MainTab from '../components/common/MainTab';
import Today from '../components/main/Today';
import AirPollution from '../components/main/AirPollution';
import Week from '../components/main/Week';
import DetailTemplate from '../templates/DetailTemplate';
import TodayDetail from '../components/detail/TodayDetail';
import Air from '../components/common/air/Air';
import News from '../components/news/News';
import {getAirPollution, get3Days, get10Days, getToday, getAddress} from '../common/apiInterface';
import Foot from '../common/Foot';
import SimpleMain from '../components/main/SimpleMain';
import AllCastMain from '../components/main/AllCastMain';

const MainContainer = ({ urlParam }) => {

    const [position, setPosition] = useState(); // 경도(nx) lng, 위도(ny) lat
    const [index, setIndex] = useState(0);
    const [targetComponent, setTargetComponent] = useState(<div>오늘날씨</div>);
    const [todayData, setTodayData] = useState();
    const [weekData, setWeekData] = useState();
    const [dustData, setDustData] = useState();
    const [address, setAddress] = useState('');
    const [isApp, setIsApp] = useState('');
    const [data, setData] = useState();
    const [positionBtn, setPositionBtn] = useState(false);

    useEffect(() => {
        window.parent.postMessage({ name: 'weatherLocation', request: 'location', click:false}, '*');
    }, []);

    useEffect(() => {
        window.parent.postMessage({ name: 'isNative'}, '*');

        window.addEventListener('message', function (e) {
           // web, 모바일 브라우저인 경우 false, native 인 경우 true
            if(e.data.isApp && e.data.isApp !== null){ 
                setIsApp(e.data.isApp);
            }
            if(e.data.lat && e.data.lat !== null && e.data.lng && e.data.lng !== null){
                setPosition({nx:e.data.lng, ny:e.data.lat});
            }
        });

        //웹 브라우저에서 위치 정보 가져오기
        function success(posi) {
            if(posi.coords.latitude && posi.coords.latitude !== null && posi.coords.longitude && posi.coords.longitude !== null){
                setPosition({nx:posi.coords.longitude, ny:posi.coords.latitude});
            }
        }
        
        function error(error) {
            console.log(`ERROR(${error.code}): ${error.message}`);
        }
        
        const options = {
            enableHighAccuracy: false,
            maximumAge: 30000,
            timeout: 5000
        };

        if(navigator.geolocation && isApp === 'false'){  //웹브라우저인 경우에만 geolocation 사용   
            navigator.geolocation.getCurrentPosition(success, error, options);
        
        }

        //위치 비허용인 상세 페이지 기본값 보여줌..
        if(urlParam.nx !== undefined && position === undefined){
            setPosition({nx:127.0996889, ny:37.53613889});

        }
    }, [isApp]);

    useEffect(()=>{
        if(position !== null && position !== undefined){
            getAddress(position)
                .then((response) => {
                    setAddress(response);
                }).catch(err => {
                    console.error(err)
                });
    
            get3Days(position)
                .then((response) => {
                    setTodayData(response)
                }).catch(err => {
                    console.error(err)
                });
        
            get10Days(position)
                .then((response) => {
                    setWeekData(response);
                }).catch(err => {
                    console.error(err)
                });
                    
            getAirPollution(position)
                .then((response) => {
                    setDustData(response)
                }).catch(err => {
                    console.error(err)
                });

            getToday(position)
                .then((response) => {
                    setData(response);
                }).catch(err => {
                    console.error(err);
                });
            
        }
    },[position])

    useEffect(()=>{
        var retVal = ''
        switch(index){
            case 0:
                retVal = <Today type={1} data={todayData}/>;
                break;
            case 1:
                retVal = <AirPollution data={dustData}/>;
                break;
            case 2:
                retVal = <Week data={weekData}/>;
                break;
            case 3:
                retVal = <Today type={2} data={todayData}/>;
                break;
            case 4:
                retVal = <Today type={3} data={todayData}/>;
                break;
            default :
                retVal = <div>Invalid value</div>;
                break;
        }
        setTargetComponent(retVal);
       //인터페이스추가
        
    }, [index, todayData, weekData])


    return (
        <React.Fragment>
            { (!urlParam.mode || urlParam.mode === 'main') ?
                <MainTemplate
                    main={position ? <SimpleMain position={position} address={address} dustData={dustData} positionBtn={positionBtn} setPositionBtn={setPositionBtn} data={data}/>:<AllCastMain />}
                />
                :
                <DetailTemplate
                    todayDetail= {<TodayDetail position={urlParam && {nx:urlParam.nx, ny:urlParam.ny}} todayData={todayData} weekData={weekData} setWeekData={setWeekData} address={urlParam && urlParam.address} view={urlParam && urlParam.view}/>}
                    mainTab={
                            <MainTemplate
                                tab={<MainTab index={index} setIndex={setIndex} />}
                                main={targetComponent}
                            />}
                    map={<Air/>}
                    newsSection={<News/>}
                    foot={<Foot/>}
                />
            }
        </React.Fragment>
    );

};

export default inject(({ editor }) => ({
    editor: editor,
}))(observer(MainContainer));
