import React, {useState, useEffect} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import NewsSection from '../common/NewsSection';
import { getApiKeywordNews } from '../../common/apiInterface';

const useStyles = makeStyles(theme => ({

    select:{
        width: '50%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background:'#ffffff',
        border: '1px solid #707070',
        borderBottom:'0px'
    },

    deSelect:{
        width: '50%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fcfcfc',
        border: '1px solid rgba(0, 0, 0, .2)',
        "&:hover": {
            opacity:0.7,
            background:'#ffffff',
            borderColor:'#9E9E9E'
        }
    }

}));

const News = () => {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [list, setList] = useState([]);

    useEffect(() => {
            getApiKeywordNews(index, 5, '날씨').then((response) => {
                if(response.data.status == 200){
                    setList(response.data.news_list);
                }
            }).catch(err => {
                console.log(err);
            });        
    },[index]);

    return(
        <React.Fragment>
            <div style={{height:'16px'}}/>
            <Typography style={{fontSize:'16px', opacity:1, color:'#000000', lineHeight:'24px', fontWeight:'bold', paddingLeft:'16px'}}>
                뉴스
            </Typography>
            <div style={{height:'17px'}}/>
            <div style={{width:'97%', display:'flex', alignItems:'center', justifyContent:'center', height:'32px', paddingLeft:'15px', marginBottom:'16px', cursor:'pointer'}}>
                <Typography 
                    className={index === 0 ? classes.select : classes.deSelect} 
                    variant={index === 0 ? 'body2' : 'body1'} 
                    style={index === 0? {borderTopLeftRadius:'4px'} : {borderTopLeftRadius:'4px', borderRight:'0px solid'}} 
                    onClick={(e)=>setIndex(0)}> 
                    정확도순
                </Typography>
                <Typography 
                    className={index === 1 ? classes.select : classes.deSelect} 
                    variant={index === 1 ? 'body2' : 'body1'} 
                    style={index === 1 ?{borderTopRightRadius:'4px'} : {borderTopRightRadius:'4px', borderLeft:'0px solid'}} 
                    onClick={(e) => setIndex(1)}>
                    최신순
                </Typography>       
            </div>
            {list && list.map((item, ind)=>{
                return (
                    <NewsSection key={ind} item={item} index={ind} selecMenu={index} length={list.length}/>
                )
            })}
            <div style={{height:'20px'}}/>
        </React.Fragment>
    )
}
export default News;