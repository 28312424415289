import React, { useEffect, useState } from 'react';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import {Typography } from "@material-ui/core";
import Sky from '../common/Sky';

const Week = ({data}) => {

    const [load, setLoad] = useState(true);

    useEffect(() => {
        if(data){
            setLoad(true);
        }
    }, [data]);


    return (
        <React.Fragment>
            <div style={{height:'6px'}}/>
            {data?
                <Fade in={load}>
                    <React.Fragment>               
                        {data && data.map((item, index) => 
                            index < 7 &&
                            <React.Fragment key={index}> 
                                <div style={{display:'grid', gridTemplateColumns:'50px 2fr 2fr 2fr', paddingLeft:'16px', paddingRight:'14px'}}>  
                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                        <div style={{height:'11px'}}/>
                                            <Typography variant={'body2'} 
                                                style={{display:'flex', alignItems:'center', justifyContent:'center'
                                                        , fontWeight:(moment(item.baseDt).format("YYYYMMDD") === moment().format("YYYYMMDD")? 'bold':200)
                                            }}>
                                                {moment(item.baseDt).format("YYYYMMDD") === moment().format("YYYYMMDD")? '오늘': moment().add(index,'d').format("ddd")}
                                            </Typography>
                                            <Typography style={{display:'flex', justifyContent:'center'}}>{moment().add(index,'d').format("MM.DD")}</Typography>
                                        <div style={{height:'8px'}}/>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', paddingRight:'15px'}}>
                                            <Typography>오전</Typography>
                                            <Typography>{item.amPop}%</Typography>
                                        </div>
                                        <div style={{display:'flex', alignItems:'center'}}><Sky item={{"skyState":item.amSkyState, "ptyState":item.amPtyState}} wid={'26px'} pm={0}/></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', paddingRight:'15px'}}>
                                            <Typography>오후</Typography>
                                            <Typography>{item.pmPop}%</Typography>
                                        </div>
                                        <div style={{display:'flex', alignItems:'center'}}><Sky item={{"skyState":item.pmSkyState, "ptyState":item.pmPtyState}} wid={'26px'} pm={9}/></div>
                                    </div>
                                    {moment(item.baseDt).format("YYYYMMDD") === moment().format("YYYYMMDD")?
                                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', paddingLeft:'15px'}}>
                                            <Typography variant={'body2'} style={{color:'#0E46E1'}}>{Number(item.minTemp)}°</Typography>
                                            <Typography variant={'body1'} style={{paddingRight:'3px', paddingLeft:'3px'}}>/ </Typography>
                                            <Typography variant={'body2'} style={{color:'#D92424'}}>{Number(item.maxTemp)}°</Typography></div>
                                    :
                                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', paddingLeft:'15px'}}><Typography>{Number(item.minTemp)}° / {Number(item.maxTemp)}°</Typography></div>
                                    }
                                </div>
                                {index < 6 && <div style={{width:'100%', height:'1px', borderBottom:'1px solid #202020', opacity:'0.1'}}/>}
                            </React.Fragment>
                        )}
                    </React.Fragment> 
                </Fade>
            :   <div style={{width:'100%', height:'400px'}}>
                    <CircularProgress style={{color:'#ACACAC', display:'flex', opacity:1, position:'relative', top:'45%', left:'45%'}} />
                </div>
            }
        </React.Fragment>
    );
}

export default Week;