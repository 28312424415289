import React from "react";
import { Typography } from '@material-ui/core';

function Watery({item}){
    
    var waterDrop ='0';
    if(0 <= item.humidity  && item.humidity < 10){
        waterDrop = '0'
    }else if(10 <= item.humidity && item.humidity < 20){
        waterDrop = '10'
    }else if(20 <= item.humidity && item.humidity < 30){
        waterDrop = '20'
    }else if(30 <= item.humidity && item.humidity < 40){
        waterDrop = '30'
    }else if(40 <= item.humidity && item.humidity < 50){
        waterDrop = '40'
    }else if(50 <= item.humidity && item.humidity < 60){
        waterDrop = '50'
    }else if(60 <= item.humidity && item.humidity < 70){
        waterDrop = '60'
    }else if(70 <= item.humidity && item.humidity < 80){
        waterDrop = '70'
    }else if(80 <= item.humidity && item.humidity < 90){
        waterDrop = '80'
    }else if(90 <= item.humidity && item.humidity < 100){
        waterDrop = '90'
    }else{
        waterDrop = '100'
    } 

    return(
        <React.Fragment>
            <div style={{height:'4px'}}/>
            <img src={'/images/watery/tear_'+waterDrop+'.png'} id='vec' alt='vec' style={{ width:'24px', height:'24px'}}/>    
            <div style={{height:'16px'}}/>
            <Typography>{item.humidity}%</Typography>
            <div style={{height:'1px'}}/>
        
        </React.Fragment>
    );
}

export default Watery;