import React, {useEffect, useLayoutEffect, useRef, useState } from 'react';
import moment from 'moment';
import { makeStyles} from '@material-ui/core';
import Header from './common/Header';
import NowPosition from './nowPostion/NowPosition';
import Map from './map/Map';
import AfterTenDays from './nowPostion/AfterTenDays';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getToday, getTomorrow, getDayAT, getAirPollution } from '../../common/apiInterface';

const useStyles = makeStyles(theme => ({
    main: {
        width: '100%',
        minWidth: '340px',
        maxWidth: '700px',
        height: '500px',
        backgroundSize: 'cover',
        position: 'relative',
    }
}));

const TodayDetail = ({position, todayData, weekData, address, view})=>{

    const [selectDayIndex, setSelectDayIndex] = useState(0);
    const [selectDay, setSelectDay] = useState(moment().format('YYYYMMDD'));
    const [viewType, setViewType] = useState(0);
    const [imgHeight, setImgHeight] = useState();
    const [background, setBackground] = useState('');
    const [data, setData] = useState();
    const [dustData, setDustData] = useState();
    const [load, setLoad]= useState(false);
    const imgRef = useRef();
    
    const classes = useStyles();

    useLayoutEffect(() => {
        function updateSize() {
            if (imgRef && imgRef.current){
                setImgHeight(imgRef.current.getBoundingClientRect().height);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);


    useEffect(() => {
        if(position !== null && position !== undefined && viewType === 0){
            //viewType === 0 -> 현위치
            //selectDayIndex === 0 -> 오늘
            selectDayIndex === 0 && getToday(position)
                .then((response) => {
                setData(response)
                }).catch(err => {
                    console.error(err)
                });
            //selectDayIndex === 1 -> 내일
            selectDayIndex === 1 && getTomorrow(position)
                .then((response) => {
                setData(response)
                }).catch(err => {
                    console.error(err)
                });
            //selectDayIndex === 2 -> 모레
            selectDayIndex === 2 && getDayAT(position)
                .then((response) => {
                    setData(response)
                }).catch(err => {
                    console.error(err)
                });
            //selectDayIndex < 3 -> 오늘/내일/모레 탭일 경우에만 미세먼지 조회
            selectDayIndex < 3 && getAirPollution({nx:position.nx, ny:position.ny, ymd:moment().add(selectDayIndex,'d').format('YYYYMMDD')})
                .then((response) => {
                    if(response){
                        setDustData(response)
                    }else{
                        setDustData('')
                    }                    
                }).catch(err => {
                    console.error(err)
                });
        }
    },[selectDayIndex])

    return(
        <div style={{position: 'relative', height: viewType === 0 && selectDayIndex !=3 ? (imgHeight ? imgHeight+'px' : '520px') : viewType === 1? '510px': moment().day() > 3 && selectDayIndex ===3 ?'584px':'459px',
                        maxWidth:'700px'}}> 
            {viewType === 0 && selectDayIndex < 3? 
                //현위치 > 오늘/내일/탭의 배경날씨 이미지
                <React.Fragment>
                    <div 
                        className={classes.main} 
                        style={{ 
                            position:'absolute',
                            top:0,
                            left:0,
                            width:'100%',
                            height:imgHeight+'px',
                            backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(84,84,84,0.4))',
                            zIndex:1,
                            }}
                    >
                    </div>   
                    <img
                        src={'/images/common/DetailImg/' + background + '.png'}
                        style={{position:'absolute', top:0, left:0, width:'100%', zIndex:0}}
                        alt='weather'
                        onLoad={(e) => {setImgHeight(imgRef.current.getBoundingClientRect().height); setLoad(true);}}
                        ref={imgRef}
                    />
                </React.Fragment>
                :
                <div>
                </div>
            }
            <div style={{position:'absolute', top: 0, left: 0, width: '100%', zIndex: 2}}>
                <div style={{backgroundImage: (''), opacity:1, zIndex:6}}>
                    <Header position={position} handleView={setViewType} handleDateChange={setSelectDay} setSelectDayIndex={setSelectDayIndex} selectDayIndex={selectDayIndex}
                            address={address} view={view}/>
                </div>
                {viewType === 0 && selectDayIndex < 3 ?
                    // 현위치 > 오늘/내일/모레 탭
                    <NowPosition imgHeight={imgHeight} selectDayIndex={selectDayIndex} setBackground={setBackground} data={data} dustData={dustData}/>  
                : viewType === 0 && selectDayIndex < 3 &&
                    <div style={{opacity:0.5, height:(imgHeight ? (imgHeight-118)+'px' : '402px')}}>
                        <CircularProgress style={{color:'#ffffff', display:'flex', opacity:1, position:'absolute', top:'45%', left:'45%'}} />
                    </div>  
                }
                {viewType === 0 && selectDayIndex === 3 && // 현위치 > 이후10일 탭
                    <AfterTenDays weekData={weekData}/>
                }
                {viewType === 1 && //전국 탭일 경우 
                    <Map selectDayIndex={selectDayIndex} code={0}/>
                }
            </div>
        </div>
    );
}

export default TodayDetail;