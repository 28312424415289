import React from 'react';
import { Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

function AirPollution({data}){  

    return(
        <React.Fragment> 
            <div style={{display:'flex', paddingTop:'16px' }}>     
                {data && data.map((item,index)=>{
                    let face = 'good';
                    switch(item.informGrade){
                        case "좋음":
                            face ='good';
                            break;
                        case "보통":
                            face = 'soso';
                            break;
                        case "나쁨":
                            face = 'bad';
                            break;
                        case "매우 나쁨":
                            face = 'soBad';
                            break;
                        default: face = 'good';
                    }            

                    return(
                        <React.Fragment key={index}>
                            <Fade in={item.informCode? true:false} >
                                <div style={{backgroundColor:(item.informGrade === '좋음'?'#67AC2B'
                                                            : item.informGrade === '보통'?'#E9AB00'
                                                            : item.informGrade === '나쁨'?'#F98018'
                                                            :'#B42E2E')
                                            , width:'100%', height:'100px', borderRadius:'4px'
                                            , display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center'}}>
                                <div style={{display:'flex', justifyContent:'center', alignContent:'center'}}>
                                        <img src={'/images/common/DetailImg/dust/'+face+'.png'} alt='dustState' 
                                            style={{width:'26px', height:'26px', display:'flex', justifyContent:'center'}}/>
                                </div>
                                <div style={{height:'7.81px'}}/>
                                    <Typography style={{color:'#ffffff', opacity:0.7, display:'flex', justifyContent:'center', alignContent:'center'
                                                        , letterSpacing: '-0.48px'}}>{item.informCode === 'PM10'? '미세먼지':'초미세먼지'}</Typography>
                                    <Typography style={{color:'#ffffff', opacity:1, display:'flex', justifyContent:'center', alignContent:'center'
                                                        , letterSpacing: '-0.48px'}}>{item.informGrade}</Typography>
                                </div>
                            </Fade>
                            {!data &&
                                <div>데이터를 조회하는 중입니다.</div>
                            }
                            {index === 0 && <div style={{width:'6px', height:'6px'}}/>}
                        </React.Fragment>
                    )
                })}       
            </div>  
        </React.Fragment>
    );
}

export default AirPollution;












