import React, {useState, useEffect} from 'react';
import moment from 'moment';
import axios from 'axios';
import WeatherOfMap from './WeatherOfMap';
import Tab from './Tab';


const Map = ({ selectDayIndex }) => {

    const [mainData, setMainData] = useState();
    const [code , setCode] = useState(0);

    useEffect(()=>{

        const SERVER_PATH = process.env.REACT_APP_API_SERVER_URL;
        const mapPath = SERVER_PATH+'/contents/weather/forecast-map?ymd='+ moment().format('YYYYMMDD');
            axios({
                method: 'get',
                baseURL: mapPath,
            }).then((response) => {
                setMainData(response.data.results)
            }).catch(err => {
                console.log(err);
            });

        if(selectDayIndex !== 0 && code === 0){
            setCode(1);
        }

    }, [selectDayIndex])

    return(
        <div style={{ 
            position:'relative',
            backgroundRepeat:'no-repeat',
            backgroundColor: '#b0d7ee',
            backgroundImage:'url(/images/common/DetailImg/map.png)', 
            backgroundSize:'360px 398px', 
            backgroundPosition:'center top',
            width:'100%',
            minHeight:'398px',
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
            }} >
            <div style={{height:'14px'}} />
            <div style={{ width:'100%', display:'flex', justifyContent:'flex-end', zIndex:5}}>
                <Tab code={code} setCode={setCode} selectDayIndex={selectDayIndex} />
                <div style={{width:'16px'}}/>
            </div>
            <div style={{position:'absolute',width:'360px',height:'100%', zIndex:1}}>
            {mainData && mainData.map((item, index)=>{
                return <WeatherOfMap key={index} value={item} code={code} selectDayIndex={selectDayIndex}/>
            })}
           </div>
        </div>

    )
}

export default Map;