import React, {useEffect, useRef, useState} from 'react';
import Sky from '../common/Sky';
import moment from 'moment';
import { getMainForecastMap, getAirPollution } from '../../common/apiInterface';
import { Typography } from '@material-ui/core';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FadeIn from 'react-fade-in';
import axios from 'axios';

function AllCastMain({}){  
    const [data, setData] = useState();

    const settings = {
      autoplay:true,
      speed: 3000,
      infinite: true,
      slidesToShow: 1,
      vertical: true,
    };

    useEffect(() => {
        getMainForecastMap()
            .then((response) => {
                setData(response);
            }).catch(err => {
                console.error(err);
              
            });
    }, []);

    // 서울, 백령도, 수원(장안구), 춘천, 강릉, 대전, 대구
    // let allXYList = [{nx:'126.9800083',ny:'37.56356944'}, {nx:'124.7186911',ny:'37.9741764'}, {nx:'127.0122222',ny:'37.30101111'}, {nx:'127.7323111',ny:'37.87854167'}, {nx:'128.8784972',ny:'37.74913611'}, {nx:'127.3865667',ny:'36.34711944'}, {nx:'128.6035528',ny:'35.86854167'}
    // , {nx:'129.3136889',ny:'35.53540833'}, {nx:'128.6401544',ny:'35.2540033'}, {nx:'126.8533639',ny:'35.156975'}, {nx:'126.3944194',ny:'34.80878889'}, {nx:'129.0769528',ny:'35.17701944'}, {nx:'127.6643861',ny:'34.75731111'}, {nx:'126.5003333',ny:'33.48569444'}];
    //울산, 창원, 광주, 목포, 부산, 여수, 제주
    // let num = 0; 
      
    // function callAirPollution(){
    //     this.interval = setInterval(() => {

    //     getAirPollution(allXYList(num))
    //         .then((response) => {
    //             if(response){
    //                 console.log(response);
    //                 setDustData(response);
    //             }  
    //             num = num+1;           
    //         }).catch(err => {
    //             console.error(err)
    //         });

    //     }, 2000 );
    // }

    const handleClick = () => {
        //상세 페이지 전국 탭으로 이동
        window.parent.postMessage({ name: 'new_window',  action: 'transform', url: process.env.REACT_APP_URL+'/rozeus_weather/?mode=detail&nx='+'126.9800083'+'&ny='+'37.56356944'+'&address=광진구 구의제3동&view=true'}, '*');
        // window.parent.postMessage({ name: 'new_window',  action: 'transform', url: process.env.REACT_APP_URL+'/rozeus_weather/?mode=detail&viewType=1'}, '*');
        //window.parent.postMessage({ name: 'new_window',  action: 'transform', url: process.env.REACT_APP_URL+'/rozeus_weather/?mode=detail&nx='+'126.9800083'+'&ny='+'37.56356944'+'&address='+'서울'+'&viewType=1'}, '*');
        
    }

    return(
        <>
        <FadeIn transitionDuration={500}>
        <Slider {...settings}>
            {data && data.map((item, index)=>{ 
                return(
                <>
                    <div style={{display:'grid', gridTemplateColumns:'50px 1fr 1fr', rowGap:'10px', gridAutoRows:'83px', alignItems:'center'}}>
                        <Sky item={{skyState:item.now_sky, ptyState:item.now_pty}} wid={'40px'} pm={moment().format('HHmm')<1800? 0:9} main={true} key={index-10}/>
                        <div onClick={handleClick} key={index} style={{cursor:'pointer'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <Typography style={{fontSize:'16px', letterSpacing:'-0.48px', color:'#000000', fontWeight:'bold', opacity:1 }}>{item.now_temp}゜</Typography> 
                                <Typography style={{fontSize:'16px', letterSpacing:'-0.48px', color:'#000000', fontWeight:300, opacity:1, position:'relative', left:'-5px' }}>{
                                    item.now_pty === '0' ?
                                        <React.Fragment>
                                            {item.now_sky === '1' && '맑음'      }
                                            {item.now_sky === '3' && '구름많음'  }
                                            {item.now_sky === '4' && '흐림'      }
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            {item.now_pty === '1' && '비'            }
                                            {item.now_pty === '2' && '비/눈'         }
                                            {item.now_pty === '3' && '눈'            }
                                            {item.now_pty === '4' && '소나기'        }
                                            {item.now_pty === '5' && '빗방울'        }
                                            {item.now_pty === '6' && '빗방울/눈날림' }
                                            {item.now_pty === '7' && '눈날림'        }
                                        </React.Fragment> 
                                }</Typography>
                                <img src='/images/common/ic_move_right.png' alt="move_button" width="24" height="24" style={{position:'relative', left:'-5px', top:'1px' }}/>
                            </div>
                            <div style={{display:'flex'}}>
                            </div>
                        </div>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            <Typography style={{fontWeight:300, paddingRight:'6px', opacity:0.7}}>{item.region_nm}</Typography>
                            <img src='/images/common/ic_position.png' style={{display:'block', width:'14px', height:'14px', cursor:'pointer'}} alt='POSITION ICON'
                                onClick={(e)=>{window.parent.postMessage({ name: 'weatherLocation', request: 'location', click:true}, '*');}} />
                        </div>
                    </div>
                </>
                )
            })} 
        </Slider>
        </FadeIn>
        </>
    );
}

export default AllCastMain;