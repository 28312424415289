import React, {  useRef, useState } from 'react';
import TimeTable from '../common/TimeTable';
import Sky from '../common/Sky';
import Wind from './Wind';
import Watery from './Watery';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, } from 'recharts';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScrollContainer from 'react-indiana-drag-scroll';


const Today = ({type, data}) => {

    const refSky = useRef(null);
    const [loadStatus, setLoadStatus] = useState(true);

    let num = -1;
    const Label = props => {
        const { x, y, value} = props;
        
        if(num < data.length){
            num = num +1;
        }
        return (
            <text
            x={x}
            y={y}
            dx={"-0.6%"}
            dy={"-10%"}
            fontSize="12"
            position="insideBottom"
            fill={data[num].tempMin?"#0e46e1": data[num].tempMax? "#d92424":"#000000"}
            opacity={data[num].tempMin?"1": data[num].tempMax? "1":"0.4"}
            >
                {value}゜
            </text>
        );
    };

    return (
        <div style={{position:'relative', paddingTop:'16px' }}> 
            <ScrollContainer style={{display:'flex'}} vertical={false} >
                <div 
                    ref={refSky}
                    style={{opacity:(loadStatus || type !== 1?1:0.3)}}                 
                >
                    <div style={{ width: '800px', display:'flex',}}>
                        {data && data.map((item, index) => {
                            return item && (
                                <div key={index} style={{width:'50px', minWidth:'50px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                                        <TimeTable  item={item} type={index} />
                                    <div style={{ height: '12px'}} />
                                    {type === 1 && 
                                        <Sky key={index} item={item}  />
                                    }
                                    {type === 2 &&
                                        <Wind index={index} item={item} />
                                    }
                                    {type === 3 &&
                                        <Watery key={index} item={item} />
                                    }
                                </div>
                            )
                        })}
                    </div>
                    {(data && type===1) && 
                        <div>
                            <div style={{height:'10px'}} />
                            <AreaChart data={data} width={50*data.length} height={47} margin={{top: 0, right: 25, bottom: 0, left: 25,}}>
                                <CartesianGrid strokeDasharray="1 3" horizontal={false}  />
                                <Area dataKey="temp" stroke='#dcf0fb' fill='#dcf0fb' label={<Label />} isAnimationActive={false} />
                                <XAxis dataKey={'time'} stroke='#D3E3EC' tickLine={false} height={1}/>
                                <YAxis hide={true} domain={['dataMin-5','dataMax + 30']}/>
                            </AreaChart>
                            <div style={{height:'5px'}} />
                        </div>
                    }
                </div>
            </ScrollContainer>
            <CircularProgress style={{color:'#ACACAC', display:'flex', alignItems:'center', justifyContent:'center', opacity:1, zIndex:10, position:'absolute', top:'45%', left:'45%', visibility:(loadStatus|| type !== 1?'hidden':'visible')}} />
        </div>
    )
}

export default Today;