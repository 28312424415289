import React from 'react';
import { makeStyles} from '@material-ui/core';
//import {SkyAmList, SkyPmList, MinTempList, MaxTempList} from '../../week/WeekData';
import Calendar from './Calendar';

const useStyles = makeStyles(theme => ({
    calendarData: {
        display:'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        width:'100%',                       
        opacity:1,                        
        paddingLeft:'2%',    
        paddingRight:'2%',                 
        letterSpacing: 'var(--unnamed-character-spacing--0-48)',
        font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-noto-sans-kr)'
    }
}));

const AfterTenDays = ({weekData}) => {
    
    // const days = Array(14).fill().map((item, index) => ++index);
    const classes = useStyles();

    //오전이면 오전예보 / 오후면 오후예보
    return(
        <React.Fragment>
            <div style={{height:'100%'}}>
                <div className={classes.calendarData}>
                    <Calendar weekData={weekData} />
                </div>
                <div style={{width:'100%', height:'16px'}}/>
                <div style={{width:'100%', height:'8px', backgroundColor:'#eeeeee'}}/>
            </div>     
        </React.Fragment>
    )
}

export default AfterTenDays;