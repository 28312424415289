import React, {useState} from 'react';
import {makeStyles, Typography, Divider} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

    select:{
        width: '47%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background:'#ffffff',
        border: '1px solid rgba(112, 112, 112, 1)',
        borderBottom:'0px'
    },
    deSelect:{
        width: '47%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fcfcfc',
        border: '1px solid rgba(0, 0, 0, .2)',
        "&:hover": {
            opacity:0.7,
            background:'#ffffff',
            borderColor:'#9E9E9E'
        }
    }
}));

const Air =({}) =>{
    const classes = useStyles();
    const [index, setIndex] = useState(0);

    return(
        <React.Fragment>
            <div style={{display:'flex', justifyContent:'center', paddingLeft:'16px', paddingRight:'16px',}}>
                <Divider style={{width:'100%', color:'#000000'}}/>
            </div>
            <div style={{height:'11px'}}/>
            <div style={{display:'flex'}}>
                <Typography style={{paddingLeft:'16px', paddingRight:'7px', paddingTop:'0px', paddingBottom:'11px'}}>제공 </Typography>
                <Typography variant='body2' style={{paddingTop:'0px', paddingBottom:'11px', fontWeight:'normal'}}> 기상청 </Typography>
            </div>
            <div style={{display:'flex', justifyContent:'center', paddingLeft:'16px', paddingRight:'16px',}}>
                <Divider style={{width:'100%', color:'#000000'}}/>
            </div>
            <div style={{paddingRight:'16px', paddingLeft:'16px', paddingTop:'11px', paddingBottom:'20px'}}>
                <Typography style={{fontSize:'12px', letterSpacing: '-0.48px'}}>
                    로제우스의 날씨 서비스는 대한민국 기상청의 국내 날씨 예보 정보를 제공하고 있습니다. 데이터 제공처의 환경에 따라 정보가 제한되어 표시 될 수 있습니다.
                </Typography>
            </div>
            <div style={{width:'100%', height:'8px', backgroundColor:'#eeeeee'}}/>
            <div style={{height:'16px'}}/>
            <Typography style={{fontSize:'16px', opacity:1, color:'#000000', lineHeight:'24px', fontWeight:'bold', paddingLeft:'16px'}}>대기/해상 정보</Typography>
            <div style={{height:'16px'}}/>
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', height:'32px', cursor:'pointer', padding:'0 16px'}}>
                <Typography 
                    className={index === 0 ? classes.select : classes.deSelect} 
                    variant={index === 0 ? 'body2' : 'body1'} 
                    style={index === 0 ?{borderTopLeftRadius: '4px'}:{borderTopLeftRadius: '4px'}} 
                    onClick={(e)=>setIndex(0)}>
                    초 미세먼지 영상
                </Typography>
                <Typography 
                    className={index === 1 ? classes.select : classes.deSelect} 
                    variant={index === 1 ? 'body2' : 'body1'} 
                    style={index === 1 ?{ }:{  borderLeft:'0px'}} 
                    onClick={(e) => setIndex(1)}>
                    미세먼지 영상
                </Typography> 
                <Typography 
                    className={index === 2 ? classes.select : classes.deSelect} 
                    variant={index === 2 ? 'body2' : 'body1'} 
                    style={index === 2 ?{}:{borderLeft:'0px'}} 
                    onClick={(e) => setIndex(2)}>
                    해류/파도
                </Typography>  
                <Typography 
                    className={index === 3 ? classes.select : classes.deSelect} 
                    variant={index === 3 ? 'body2' : 'body1'} 
                    style={index === 3 ?{ borderTopRightRadius: '4px' }:{ borderTopRightRadius: '4px', borderLeft:'0px'}} 
                    onClick={(e) => setIndex(3)}>
                    바람/온도
                </Typography>        
            </div>
            <div style={{width:'100%', display:'flex', justifyContent:'center', paddingTop:'16px'}}>
            {index === 0 &&
                <iframe title='MicroDust25' id='MicroDust25' width='94%' height='360px' src='https://earth.nullschool.net/ko/#current/particulates/surface/level/overlay=pm2.5/orthographic=-232.63,38.32,1690'  scrolling="no"></iframe>
            }
            {index === 1 &&
                <iframe title='MicroDust10' id='MicroDust10' width='94%' height='360px' src='https://earth.nullschool.net/ko/#current/particulates/surface/level/overlay=pm10/orthographic=-232.63,38.32,1690'  scrolling="no"></iframe>
            }
            {index === 2 &&
                <iframe title='Ocean' id='Ocean' width='94%' height='360px' src='https://earth.nullschool.net/ko/#current/ocean/surface/currents/overlay=primary_waves/orthographic=-232.63,38.32,1690'  scrolling="no"></iframe>
            }
            {index === 3 &&
                <iframe title='Wind' id='Wind' width='94%' height='360px' src='https://earth.nullschool.net/ko/#current/wind/surface/level/overlay=temp/orthographic=-232.63,38.32,1690'  scrolling="no"></iframe>
            }
            </div>
            <div style={{display:'flex'}}>
                <Typography style={{paddingLeft:'16px', paddingRight:'7px', paddingTop:'11px', paddingBottom:'11px'}}>제공 </Typography>
                <Typography variant='body2' style={{paddingTop:'11px', paddingBottom:'11px', fontWeight:'normal'}}> earth.nullschool.net</Typography>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
            <div style={{width:'94%', height:'1px', opacity:'10%', backgroundColor: '#000000'}}/>
            </div>
            <div style={{display:'flex'}}>
            <Typography style={{paddingLeft:'16px', paddingRight:'7px', paddingTop:'11px', paddingBottom:'11px'}}>관련 </Typography>
            <Typography variant='body2' style={{paddingTop:'11px', paddingBottom:'11px', fontWeight:'normal'}}> 미세먼지 정보, 해상 정보, 바람 정보</Typography>
            </div>
            <div style={{width:'100%', height:'8px', backgroundColor:'#eeeeee'}}/>
        </React.Fragment>
    )
}

export default Air;
