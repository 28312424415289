import { Typography } from "@material-ui/core";
import React from "react";
import moment from 'moment';

const Sky = ({item, wid, pm, main}) => {
    const { skyState, ptyState} = item;
    
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            {
                ptyState == '0' ?
                    <React.Fragment>
                        {skyState === '1' && pm === 0 && <img src={main?'/images/sky/center_s1.png':'/images/sky/s1.png'} alt='맑음' style={{width:wid?wid:'26px', height:wid?wid:'26px'}}/>}
                        {skyState === '1' && pm === 9 && <img src={main?'/images/sky/center_s1_night.png':'/images/sky/s1_night.png'} alt='맑음' style={{width:wid?wid:'26px', height:wid?wid:'26px'}}/>} 
                        {skyState === '1' && item.baseTime && item.baseTime < '1800' && item.baseTime > '0300'?
                            <img src={main?'/images/sky/center_s1.png':'/images/sky/s1.png'} alt='맑음' style={{width:wid?wid:'26px', height:wid?wid:'26px'}}/>
                        : skyState === '1' && item.baseTime && 
                            <img src={main?'/images/sky/center_s1_night.png':'/images/sky/s1_night.png'} alt='맑음' style={{width:wid?wid:'26px', height:wid?wid:'26px'}}/>
                        }
                        {skyState === '3' && <img src={main?'/images/sky/center_s3.png':'/images/sky/s3.png'} alt='구름많음' style={{ width:wid?wid: '26px', height:wid?wid:'26px'}} />}
                        {skyState === '4' && <img src={main?'/images/sky/center_s4.png':'/images/sky/s4.png'} alt='흐림' style={{ width:wid?wid: '26px', height:wid?wid:'26px'}} />}
                    </React.Fragment>
                :
                    <React.Fragment>
                        {ptyState === '1' && <img src={main?'/images/sky/center_p1.png':'/images/sky/p1.png'} alt='비' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '2' && <img src={main?'/images/sky/center_p2.png':'/images/sky/p2.png'} alt='비/눈' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '3' && <img src={main?'/images/sky/center_p3.png':'/images/sky/p3.png'} alt='눈' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '4' && <img src={main?'/images/sky/center_p4.png':'/images/sky/p4.png'} alt='소나기' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '5' && <img src={main?'/images/sky/center_p5.png':'/images/sky/p5.png'} alt='빗방울' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '6' && <img src={main?'/images/sky/center_p6.png':'/images/sky/p6.png'} alt='빗방울/눈날림' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '7' && <img src={main?'/images/sky/center_p7.png':'/images/sky/p7.png'} alt='눈날림' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '8' && <img src={main?'/images/sky/center_p8.png':'/images/sky/p8.png'} alt='구름많고비' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '9' && <img src={main?'/images/sky/center_p9.png':'/images/sky/p9.png'} alt='구름많고눈' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '10' && <img src={main?'/images/sky/center_p10.png':'/images/sky/p10.png'} alt='구름많고비/눈' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                        {ptyState === '11' && <img src={main?'/images/sky/center_p11.png':'/images/sky/p11.png'} alt='구름많고소나기' style={{ width:wid?wid: '26px', height:wid?wid:'26px' }} />}
                    </React.Fragment>
            }
        </div>
    )
}

export default Sky;