import { Typography } from "@material-ui/core";
import React from "react";
import moment from 'moment';

const DetailSky = ({data, }) => {
    return (
        <React.Fragment>
            <div style={{height:'80px' }}>
                <React.Fragment>
                    {data.ptyState && data.skyState ?
                        <img src={'/images/common/DetailImg/sky/'+
                        (data.ptyState !== '0' ? 'p' + data.ptyState
                                                : data.skyState === '1' && moment().format('HHmm') > 1800 ? 's' + data.skyState + '_night'
                                                : 's' + data.skyState 
                        ) +'.png'}              
                        alt='skyFace' 
                        style={{width:'80px'}}/>
                    : <img src={'/images/common/DetailImg/ic_weather_error.png'} 
                        alt="error" 
                        style={{width:'80px'}}/>
                    }
                </React.Fragment>
                
            </div>
            {data.temp ? <div style={{display:'flex', textAlign:'center', position:'relative'}}> 
                            <Typography variant={'h4'} style={{ width:'130px', textAlign:'center'}}>
                                {data.temp}
                            </Typography> 
                            <Typography variant={'h4'} style={{position:'absolute', left:100}}>゜</Typography> 
                        </div>
                :<Typography variant={'h4'} style={{ width:'130px', textAlign:'center', fontSize:'50px'}}>
                    - 
                </Typography>}
            <Typography variant={'h5'} style={{paddingTop:'16px', textAlign:'center', paddingLeft:'45px', paddingRight:'45px',}}>{
                    data.ptyState === '0' ?
                        <React.Fragment>
                            {data.skyState === '1' && '맑음'      }
                            {data.skyState === '3' && '구름많음'  }
                            {data.skyState === '4' && '흐림'      }
                        </React.Fragment>
                    :
                        <React.Fragment>
                            {data.ptyState === '1' && '비'            }
                            {data.ptyState === '2' && '비/눈'         }
                            {data.ptyState === '3' && '눈'            }
                            {data.ptyState === '4' && '소나기'        }
                            {data.ptyState === '5' && '빗방울'        }
                            {data.ptyState === '6' && '빗방울/눈날림' }
                            {data.ptyState === '7' && '눈날림'        }
                            {data.ptyState === null && '데이터 제공사 문제로 일시적으로 일부 날씨 서비스를 이용할 수 없습니다'}
                        </React.Fragment> 
            }</Typography>
        </React.Fragment>
    )
}

export default DetailSky;