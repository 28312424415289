import React, {useState, useEffect} from 'react';
import {Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

const DetailWind = ({item}) =>{
    const [wsd, setWsd] = useState(0);
    const [vec, setVec] = useState();
    const [load, setLoad] = useState(false);

    useEffect(()=>{
       setWsd(item.wsd);
       setVec(item.vec);
       setLoad(true);
    },[item])

    return(
        <React.Fragment>
            <div style={{textAlign:'center', paddingTop:'9px', paddingBottom:'5px'}}>
                {/* <Fade in={load}> */}
                <img src='/images/common/DetailImg/wind/vec.png' alt='' style={{width:'24px', height:'24px', transform: 'rotate('+(vec-180)+'deg)'}}/>
                {/* </Fade> */}
            </div>
            <div style={{textAlign:'center'}}>
                {/* <Fade in={load}> */}
                    <Typography variant={'h2'} style={{}}>{wsd}m/s</Typography>
                {/* </Fade> */}
            </div>
        </React.Fragment>
    )
}
export default DetailWind;