import React, { useEffect, useState } from "react";
import {Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

function DetailWatery({reh}){
    
    const [waterDrop, setWaterDrop] = useState(0);
    const [load, setLoad] = useState(false);
    

    useEffect(()=>{
        if(0 <= reh  && reh < 10){
            setWaterDrop('0');
        }else if(10 <= reh && reh < 20){
            setWaterDrop('10');
        }else if(20 <= reh && reh < 30){
            setWaterDrop('20');
        }else if(30 <= reh && reh < 40){
            setWaterDrop('30');
        }else if(40 <= reh && reh < 50){
            setWaterDrop('40');
        }else if(50 <= reh && reh < 60){
            setWaterDrop('50');
        }else if(60 <= reh && reh < 70){
            setWaterDrop('60');
        }else if(70 <= reh && reh < 80){
            setWaterDrop('70');
        }else if(80 <= reh && reh < 90){
            setWaterDrop('80');
        }else if(90 <= reh && reh < 100){
            setWaterDrop('90');
        }else{
            setWaterDrop('0');
        } 

        if(reh){
            setLoad(true);
        }
    },[reh])

    return(
        <React.Fragment>
            <div style={{textAlign:'center', paddingTop:'9px', paddingBottom:'5px'}}>
                {/* <Fade in={load}> */}
                <img src={'/images/common/DetailImg/watery/tear'+waterDrop+'.png'} id='vec' alt='vec' style={{width:'24px', height:'24px'}}/>
                {/* </Fade> */}
            </div>        
            <div style={{textAlign:'center', }}>
                {/* <Fade in={load}> */}
                <Typography variant={'h2'} style={{}}>{reh}%</Typography> 
                {/* </Fade> */}
            </div>       
        </React.Fragment>
    );
}

export default DetailWatery;