import React from "react";
import moment from 'moment';
import { Typography } from "@material-ui/core";


const Calendar = ({weekData}) => {

    let startSunday = moment(moment().startOf('week').format('YYYYMMDD'));
    let lastSaturday = moment(moment().add(10,'d').endOf('week').format('YYYYMMDD'));
    let diff = lastSaturday.diff(startSunday,'days'); 
    let week = 2;
    let todayNumber = moment(moment().day()); //오늘을 요일별 숫자(0~6)로 표현함 일: 0 ~ 토: 6
    let endDay = moment(moment().add(10,'d').format('YYYYMMDD')); //오늘부터 10일 뒤 날짜 의 요일별 숫자
    let todayTime = moment().hour();
    let skyNum = 0;  let maxNum = 0; let minNum = 0;

    const arr = Array(7).fill().map((item, index) => ++index);

    if(diff > 15){
        week = 3;
    }

    const calendarArr=()=>{
        let result = [];
        for(var i =1; i < week+1; i++){
            result = result.concat(<tr style={{borderTop:'1px solid rgba(0, 0, 0, .1)'}}>{calendarDays({i})}</tr>); 
            result = result.concat(<tr>{calendarSky({i})}</tr>);
            result = result.concat(<tr>{calendarMaxTemp({i})}</tr>); 
            result = result.concat(<tr style={{ paddingBottom:'14px'}}>{calendarMinTemp({i})}</tr>); 
        }
        return result;
    }

    const calendarDays = ({i}) =>{
        let result = [];
        let startDay = 7*(i-1);
        for(var n = startDay; n < 7*i; n++){
            result = result.concat(
                <td key={n} style={{ textAlign:'center', paddingBottom:'8px', paddingTop:'8px'}}>
                    <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography 
                            style={{color:(moment().startOf('week').add(n,'d').format('D') === moment().format('D')?'#ffffff':
                                        moment().startOf('week').add(n,'d').day() === 0 ?'#D92424'
                                        :moment().startOf('week').add(n,'d').day() === 6?'rgb(14, 70, 225)':'#000000') //일요일, 토요일일 경우 색 바꾸기
                                , fontsize:'10px'
                                , opacity:(moment().startOf('week').add(n,'d').format('YYYYMMDD') < moment().format('YYYYMMDD')? 0.4
                                    : moment().startOf('week').add(n,'d').format('YYYYMMDD') > moment().add(10,'d').format('YYYYMMDD')? 0.4:1)
                                , backgroundColor:(moment().startOf('week').add(n,'d').format('D')===moment().format('D')?'#575757':'#ffffff')
                                , borderRadius:'50%'
                                , width:(moment().startOf('week').add(n,'d').format('D')===moment().format('D')?'25px':'100%')  
                                , height:'25px'  
                                , display:'flex'
                                , justifyContent:'center'
                                , alignItems:'center' 
                        }}>
                            {moment().startOf('week').add(n,'d').format('D')}
                        </Typography>
                    </div>
                </td>);
        }
        return result;
    }

    const calendarSky = ({i}) =>{
        let result = [];
        let startDay = 7*(i-1);

        for(var n = startDay; n < 7*i; n++){
            if(7*i===7 && n < todayNumber){//첫째 주이면서 오늘 이전이면 빈 칸 만들기
                result = result.concat(<td></td>);
            }else if(i===week && endDay.diff(moment().startOf('week').add(n,'d').format('YYYYMMDD') ,'days')<0){//마지막 주이면서 데이터가 없는 날짜는 빈칸 만들기
                result = result.concat(<td></td>);
            }else{
                result = result.concat(
                    <td style={{ textAlign:'center', paddingBottom:'9px'}}>
                    {todayTime < 18? 
                        <img src={'/images/sky/'
                            +(weekData[skyNum].amPtyState === null && weekData[skyNum].amSkyState === null? 
                            'ic_error'
                            : weekData[skyNum].amPtyState === '0' ? 'center_s'+ weekData[skyNum].amSkyState
                            : 'center_p'+ weekData[skyNum].amPtyState)+'.png'} 
                            alt='sky' 
                            style={{width:'28px', height:'28px'}}
                            onError={(e)=>{e.target.src='/images/sky/ic_error.png'}}/>
                    : <img src={'/images/sky/'
                        +(weekData[skyNum].pmPtyState === null && weekData[skyNum].pmSkyState === null? 
                        'ic_error'
                        : weekData[skyNum].pmPtyState !== '0' ? 'center_p'+ weekData[skyNum].pmPtyState 
                        : weekData[skyNum].pmSkyState === '1' ?
                        'center_s'+ weekData[skyNum].pmSkyState+'_night'
                        : 'center_s'+ weekData[skyNum].pmSkyState)
                        +'.png'} 
                            alt='sky' 
                            style={{width:'28px', height:'28px'}}
                            onError={(e)=>{e.target.src='/images/sky/ic_error.png'}}/>
                    } 
                    </td>);
                skyNum = skyNum+1;
            }
        }
        return result;
    }

    const calendarMaxTemp = ({i}) =>{
        let result = [];
        let startDay = 7*(i-1);
        
        for(var n = startDay; n < 7*i; n++){
            if(7*i===7 && n < todayNumber){//첫째 주이면서 오늘 이전이면 빈 칸 만들기
                result = result.concat(<td></td>);
            }else if(i===week && endDay.diff(moment().startOf('week').add(n,'d').format('YYYYMMDD') ,'days')<0){//마지막 주이면서 데이터가 없는 날짜는 빈칸 만들기
                result = result.concat(<td></td>);
            }else{
                result = result.concat(<td style={{ textAlign:'center'}}><Typography style={{color:'#000000', fontsize:'12px', opacity:1, fontWeight:'500', paddingLeft:'10px'}}>{Number(weekData[maxNum].maxTemp)}゜</Typography></td>);
                maxNum = maxNum+1;  
            }
        }
        return result;
    }

    const calendarMinTemp = ({i}) =>{
        let result = [];
        let startDay = 7*(i-1);

        for(var n = startDay; n < 7*i; n++){
            if(7*i===7 && n < todayNumber){//첫째 주이면서 오늘 이전이면 빈 칸 만들기
                result = result.concat(<td></td>);
            }else if(i===week && endDay.diff(moment().startOf('week').add(n,'d').format('YYYYMMDD') ,'days')<0){//마지막 주이면서 데이터가 없는 날짜는 빈칸 만들기
                result = result.concat(<td></td>);
            }else{
                result = result.concat(<td style={{ textAlign:'center'}}><Typography style={{color:'#000000', fontsize:'12px', opacity:1 , paddingBottom:'14px', paddingLeft:'10px'}}>{Number(weekData[minNum].minTemp)}゜</Typography></td>);
                minNum = minNum+1;
            }
        }
        return result;
    }

    return (
        <div style={{width:'100%'}}>
            { weekData && 
            <table style={{width: '100%', borderCollapse:'collapse'}}>
                <thead style={{backgroundColor:'#FCFCFC', width:'100%'}}>
                    <tr style={{height:'36px', borderBottom:'1px solid #D2D2D2', borderTop:'1px solid #D2D2D2', zIndex:0}}>
                    {arr && arr.map((item, index)=>{
                        return(
                            <th key={index} style={{color: index === 0? '#D92424': index === 6? '#0E46E1':'#000000', fontsize:'14px', opacity:1, textAlign:'center'}}>
                                {moment().day(index).format('dd')}
                            </th>
                            )
                    })}
                    </tr>
                </thead>
                <tbody >
                    {calendarArr()}                
                </tbody>
            </table> }
        </div>
    );
}


export default Calendar;