import { createMuiTheme } from '@material-ui/core';

export const normal = createMuiTheme({
    palette: {
        background: {
            default: "#ffffff",
            paper: "#ffffff",
            footer:'#f8f8f8',
            sub:{
                chapCard: '#ffffff',
                subBackground:'#FFF9F9',
                chapBack: '#CD4D4D',
                tabHover:'#FFFDFD'
            }
        },
        text: {
            primary: "#000000",
            secondary:'#ED1C24',
            normal:'#5d5d5d',
            highlight:'#1e1e1e',
            footer: '#ED1C24',
            footerCaption:'#333333',
            sub:{
                primary: '#ED1C24',
                sub1:'#1e1e1e',
                sub2:'#333333',
                sub3: '#CD4D4D'
            },
        },
        primary:{
            main:'#ffffff',
        },
        action :{
            hover: '#F8F8F8'
        },
        button:{
            hover:'#CD4D4D',
            active: '#B13F3F',
            hoverText:'#ffffff'
        },
        button2: {
            normal:'#ED1C24',
            hover: '#DE1B22',
            active: '#CC161D',
            text: '#ffffff'
        },
        divider: '#ECECEC',
        menuUnderLine: '#1E1E1E',
        footerDivider: '#E2E2E2',
        roadmapPrimary: '#CD4D4D',
        subBox: '#F0D6D6',
        circleBorder: '#DFDFDF',
        subCard: '#ffffff',
        divider2: '#1E1E1E',
        linkBox: '#F0D6D6',
        inputBorder:'#5D5D5D',
        inputBorderHover: '#DF8686',
        inputBorderFocus: '#ED1C24'

    },
    typography: {
        h1:{
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#ffffff',
            letterSpacing: '-0.64px',
        },
        h2: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '12px',
            lineHeight: 1.42,
            color: '#ffffff',
            letterSpacing: '-0.48px',
        },
        h3: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '10px',
            lineHeight: '22px',
            color: '#ffffff',
        },
        h4: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '50px',
            lineHeight: '60px',
            color: '#ffffff',
            letterSpacing: '-2px',
        },
        h5: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '18px',
            lineHeight: '1.44',
            letterSpacing: '26px',
            letterSpacing: '-0.72px',
            color: '#ffffff',
            // "&:hover": {
            //     color: '#1e1e1e',
            // },
        },
        subtitle1: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '52px',
            lineHeight: '58px',
        },
        subtitle2: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '22px',
            lineHeight: '40px',
        },
        subtitle3: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '1.5',
            letterSpacing: '-0.64px',
        },
        body1: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '13px',
            color: '#000000',
            lineHeight: '17px',
            opacity:'0.6',
        },
        body2: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '13px',
            lineHeight: '17px',
            color: '#202020',
            opacity:'1'
        },
        body3: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: 'normal',
            color: '#202020',
            opacity:'1'
        },
        body4: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: 'normal',
            color: '#202020',
            opacity:'1'
        },
        h6: {
            fontFamily: 'Noto Sans KR',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '288px',
            lineHeight:'240px',
            color:'#F6F4F3',
            marginLeft: '-11px',
        },
        overline: {
            cursor: 'pointer',
            fontFamily: 's-core_dream6_bold',
            fontStyle: 'normal',
            fontWeight: 200,
            fontSize: '16px',
            lineHeight: '',
            "&:hover": {
                borderBottom:'1.5px solid #1e1e1e'
            }
        },

    },
});