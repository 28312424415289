import React, { useEffect, useState } from "react";
import moment from 'moment';
import "moment/locale/ko";
import { Typography } from "@material-ui/core";

function TimeTable({item, type}){
    const [time, setTime] = useState(parseInt(item.baseTime.substring(0, 2),10))

    useEffect(() => {
        if (item.baseDt === moment().add(1, 'd').format('YYYYMMDD')){
            if(item.baseTime === '0000'){
                setTime('내일')
            }
        }else if (item.baseDt === moment().add(2, 'd').format('YYYYMMDD')){
            if (item.baseTime === '0000') {
                setTime('모레')
            }
        }else if (item.baseDt === moment().add(3, 'd').format('YYYYMMDD')){
            if (item.baseTime === '0000') {
                setTime('글피')
            }
        }

    },[item])

    return(
        <Typography variant={(type === 0 || item.baseTime === '0000')? 'body2' : 'body1'}>{time} {type === 0 && '시'}</Typography>
    );
}

export default TimeTable;