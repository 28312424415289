import React from 'react';
import { Typography } from '@material-ui/core';

const Foot = () =>{

    return(
        <React.Fragment>
            <div style={{height:'194px', backgroundColor:'#E2E2E2', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <div style={{height:'32px'}}/>
                <img src='/images/common/footer_up.png' alt='go to up' style={{width:'56px', height:'56px', cursor:'pointer'}} 
                    onClick={(e)=>{window.scrollTo(0,0)}}/>
                <div style={{height:'24px'}}/>
                <Typography variant='h2' style={{color:'#000000', opacity:0.6}}>본 컨텐츠의 저작권은 제공처에 있으며,</Typography>
                <Typography variant='h2' style={{color:'#000000', opacity:0.6}}>이를 무단이용하는경우 저작권 법 등에 따라 법적 책임을 질 수 있습니다.</Typography>
                <div style={{height:'18px'}}/>
                <Typography variant='h2' style={{color:'#000000', opacity:0.6}}> ⓒ Bflysoft Corp.</Typography>
                <div style={{height:'32px'}}/>
            </div>
        </React.Fragment>
    )
}

export default Foot;