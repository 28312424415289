import React, {useEffect, useState} from 'react';
import { Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';

const DetailDust = ({dustData, dust}) =>{
    const [grade, setGrade] = useState(["-"]);
    const [load, setLoad] = useState(false);

    useEffect(()=>{
      if(dustData){

        if(dustData[0].informCode === dust){
          setGrade(dustData[0].informGrade)
          setLoad(true)
        }else{
          setGrade(dustData[1].informGrade)
          setLoad(true)
        }
      }else{
        setGrade('-')
        setLoad(true)
      }
          
      
      //setGrade(dustData.informGrade)
    },[dustData])

    return(
        <React.Fragment>
            <div style={{textAlign:'center', paddingTop:'9px', paddingBottom:'5px'}}>
              {/* <Fade in={load}>  */}
                {grade && 
                  grade === '매우 나쁨'?  <img src={'/images/common/DetailImg/dust/soBad.png'} alt='sobad'  style={{width:'24px', height:'24px'}}/>
                : grade === '나쁨' ?     <img src={'/images/common/DetailImg/dust/bad.png'} alt='bad'  style={{width:'24px', height:'24px'}}/>
                : grade === '보통' ?     <img src={'/images/common/DetailImg/dust/soso.png'} alt='soso'  style={{width:'24px', height:'24px'}}/>
                :                        <img src={'/images/common/DetailImg/dust/good.png'} alt='good'  style={{width:'24px', height:'24px'}}/>
                }
              {/* </Fade> */}
            </div>
            {/* <Fade in={load}>  */}
              {grade &&
              <Typography variant={'h2'} style={{textAlign:'center'}}>{grade} </Typography>}
            {/* </Fade> */}
        </React.Fragment>
    )
    
}
export default DetailDust;